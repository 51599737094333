import { makeAutoObservable } from 'mobx'
import { getGoalsRowsForNotification } from '~/pages/Journeys/Journey.service'
import { IGoalPerformance } from '~/pages/Journeys/JourneyReport/Store/JourneyReport.interface'
import {
  ChartDataType,
  IChartData,
  ISummary
} from '~/pages/Campaign/CampaignReports/Model/Report.interface'
import { IPushReport } from '../../Notification.interface'
import { IPreview, PreviewParts } from '~/dataStore/Campaign/Campaign.interface'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'

export default class PushReport {
  public variant = 0

  public isAlert: undefined | boolean = false

  constructor(
    public report: IPushReport,
    isAlert?: boolean,
    variant?: number,
    public controlGroupParsed?: string,
    public notificationPreview?: IPreview
  ) {
    makeAutoObservable(this, undefined, { autoBind: true })
    this.variant = variant ?? 0
    this.isAlert = isAlert
  }

  public get summaryNumbers(): ISummary[] {
    if (!this.report) return []

    const { send } = this.report

    return [
      {
        label: 'Sent',
        content: send.totalUniq ?? '- -',
        className: 'summary-field--green',
        tooltip:
          'The total number of push notifications that were sent to users who opted into push app notifications with an active device.',
        icon: 'icon--sent'
      },
      {
        label: 'Control Group',
        content: this.controlGroupParsed ?? '- -',
        className: 'summary-field--green',
        tooltip:
          'Percentage of the chosen ‘Target’ criteria who will not receive the campaign.',
        icon: 'icon--control-group'
      }
    ]
  }

  public get numbers(): ISummary[] {
    if (!this.report) return []
    if (this.isAlert) {
      return [
        { label: 'Delivered', disabled: true },
        { label: 'Pending Delivery', disabled: true },
        { label: 'Clicked', disabled: true },
        { label: 'Bounced', disabled: true }
      ]
    }

    const { delivery, pendingDelivery, engagement, bounce } = this.report
    return [
      {
        label: 'Delivered',
        content: delivery.totalUniq,
        tooltip:
          'Total number of push notifications successfully delivered to the target audience.',
        rateLabel: 'Delivery Rate',
        rate: delivery.totalUniqRate,
        disabled: this.isAlert
      },
      {
        label: 'Pending Delivery',
        content: pendingDelivery?.totalUniq ?? '- -',
        tooltip:
          'Total number of push notifications that were delivered yet to receive system confirmation. Confirmations can take up to some time to receive; please contact your Pulsate partner for more information.',
        rate: pendingDelivery?.totalUniqRate,
        rateLabel: 'Pending Delivery Rate',
        disabled: this.isAlert
      },
      {
        label: 'Clicked',
        content: engagement?.totalUniq ?? '- -',
        tooltip:
          'The total number of push notifications that were delivered and clicked.',
        rate: engagement?.totalUniqRate,
        rateLabel: 'Click Rate',
        disabled: this.isAlert
      },
      {
        label: 'Bounced',
        content: bounce?.totalUniq ?? '- -',
        tooltip:
          'Total number of push notifications that were delivered but bounced because device push permissions were disabled.',
        rate: bounce?.totalUniqRate,
        rateLabel: 'Bounce Rate',
        disabled: this.isAlert
      }
    ]
  }

  public get charts(): IChartData[] {
    if (!this.report) return []

    if (this.isAlert) {
      return [{ name: 'Sent', valueCurrent: '' }]
    }

    const {
      send,
      delivery,
      bounce,
      dismiss,
      buttonClickOne,
      buttonClickTwo,
      buttonClickThree,
      buttonClickReply,
      pushClick,
      engagement
    } = this.report

    return [
      {
        name: 'Sent',
        valueCurrent: send.totalUniq,
        data: send.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      {
        name: 'Delivered',
        valueCurrent: delivery.totalUniq,
        data: delivery.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      bounce && {
        name: 'Bounced',
        valueCurrent: bounce.totalUniq,
        data: bounce.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      {
        name: 'Dismissed',
        valueCurrent: dismiss.totalUniq,
        data: dismiss.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      buttonClickOne && {
        name: 'Button Click One',
        valueCurrent: buttonClickOne.totalUniq,
        data: buttonClickOne.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      buttonClickTwo && {
        name: 'Button Click Two',
        valueCurrent: buttonClickTwo.totalUniq,
        data: buttonClickTwo.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      buttonClickThree && {
        name: 'Button Click Three',
        valueCurrent: buttonClickThree.totalUniq,
        data: buttonClickThree.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      buttonClickReply && {
        name: 'Button Click Reply',
        valueCurrent: buttonClickReply.totalUniq,
        data: buttonClickReply.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      {
        name: 'Push Clicks',
        valueCurrent: pushClick.totalUniq,
        data: pushClick.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      engagement && {
        name: 'Total Clicks',
        valueCurrent: engagement.totalUniq,
        data: engagement.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      engagement?.uniqRateGraph && {
        name: 'Click Rate',
        valueCurrent: `${engagement.totalUniqRate}%`,
        data: engagement.uniqRateGraph?.slice() || [],
        dataType: ChartDataType.PERCENTAGE
      }
    ].filter(Boolean) as IChartData[]
  }

  public get goals(): IGoalPerformance[] {
    if (!this.report) {
      return []
    }

    return getGoalsRowsForNotification({
      name: MCampaignTypeToName.get(this.report.type) || '- -',
      goals: this.report.goals
    })
  }

  public get preview(): PreviewParts | undefined {
    if (!this.notificationPreview) return undefined

    return this.notificationPreview.push.variants[this.variant]
  }
}

import { action, computed, makeObservable, observable, reaction } from 'mobx'

import {
  ICampaignModel,
  ITargetingPayload
} from '~/dataStore/Campaign/Campaign.interface'
import {
  MAX_DWELL_TIME,
  MIN_DWELL_TIME,
  TARGETS
} from '~/pages/CampaignBuilder/Email/consts'
import { ValidationTypes } from '../EmailBuilder.interface'
import NewRegisteredField from '../RegisteredField.model'
import StepStore from '../StepStore'
import Beacons from './Beacons'
import Events from './Events'
import Geofences from './Geofences'
import Segments from './Segments'

export default class Targeting extends StepStore {
  // additional fields to keep information about validity
  targetingOption = new NewRegisteredField<string | boolean>('')

  targetingChoosenOption = new NewRegisteredField<string | boolean>('')

  targetingDwellTime = new NewRegisteredField<string>('', true)

  segments = new Segments()

  beacons = new Beacons()

  geofences = new Geofences()

  events = new Events()

  constructor(campaign?: ICampaignModel) {
    super()

    makeObservable(this, {
      targetingOption: observable,
      targetingChoosenOption: observable,
      targetingDwellTime: observable,
      segments: observable,
      beacons: observable,
      geofences: observable,
      events: observable,
      reset: action.bound,
      isSegmentsOnlyActiveTarget: computed,
      activeTargetsCount: computed,
      isSingleTarget: computed,
      isMultipleAndNotSegmentType: computed,
      getOnlyActiveTargetName: computed,
      validateStep: action,
      validateTargetingOption: action.bound,
      validateTargetingChoosenOptions: action.bound,
      validateTargetingDwellTime: action.bound,
      registeredFields: computed,
      targetingFields: computed
    })

    this.getTargetCount = this.getTargetCount.bind(this)
    this.isTargetActive = this.isTargetActive.bind(this)

    if (campaign) {
      this.segments.getAppId = campaign.getAppId

      if (campaign.addDisposer) {
        campaign.addDisposer(
          reaction(
            () =>
              this.geofences.selectedList.map(
                (geofence) => geofence.dwellTime.value
              ),
            () => this.validateTargetingDwellTime()
          )
        )

        campaign.addDisposer(
          reaction(
            () => this.activeTargetsCount,
            () => {
              this.targetingChoosenOption.setValue(
                !this.targetingChoosenOption.value
              )
            }
          )
        )
        campaign.addDisposer(
          reaction(
            () => ({
              isActive: this.targetingFields.map((field) => field.isActive),
              values: this.targetingFields.map((field) => field.selectedCount)
            }),
            () => {
              this.targetingOption.setValue(!this.targetingOption.value)
            }
          )
        )
      }
    }
  }

  reset(
    targetName:
      | TARGETS.SEGMENTS
      | TARGETS.BEACONS
      | TARGETS.GEOFENCES
      | TARGETS.EVENTS
  ): void {
    switch (targetName) {
      case TARGETS.SEGMENTS:
        this.segments.setActive(false)
        break
      case TARGETS.BEACONS:
        this.beacons.setActive(false)
        break
      case TARGETS.GEOFENCES:
        this.geofences.setActive(false)
        break
      case TARGETS.EVENTS:
        this.events.setActive(false)
        break
      default:
        break
    }
  }

  // Utils
  public isTargetActive(
    targetName: Exclude<TARGETS, TARGETS.SUBSCRIPTIONS | TARGETS.IN_APP_EVENTS>
  ): boolean | undefined {
    return this[targetName]?.isActive
  }

  public getTargetCount(target: TARGETS | null): number {
    let count = null
    switch (target) {
      case TARGETS.SEGMENTS:
        count = this.segments.selectedCount
        break
      case TARGETS.BEACONS:
        count = this.beacons.selectedCount
        break
      case TARGETS.EVENTS:
        count = this.events.selectedCount
        break
      case TARGETS.GEOFENCES:
        count = this.geofences.selectedCount
        break
      default:
        count = 0
    }
    return count
  }

  get isSegmentsOnlyActiveTarget(): boolean {
    return (
      this.segments.isActive &&
      !this.beacons.isActive &&
      !this.geofences.isActive &&
      !this.events.isActive
    )
  }

  get activeTargetsCount(): number {
    return [
      this.segments.selectedCount,
      this.beacons.selectedCount,
      this.geofences.selectedCount,
      this.events.selectedCount
    ].filter(Boolean).length
  }

  get isSingleTarget(): boolean {
    return this.activeTargetsCount === 1
  }

  get isMultipleAndNotSegmentType(): boolean {
    return !this.isSingleTarget && !this.segments.isActive
  }

  get getOnlyActiveTargetName(): null | TARGETS {
    if (!this.isSingleTarget) return null
    return [this.segments, this.beacons, this.events, this.geofences].filter(
      (target) => target.isActive
    )[0].targetName
  }

  // Validation
  public validateStep(): void {
    this.validateTargetingOption()
    this.validateTargetingChoosenOptions()
    this.validateTargetingDwellTime()

    this.beenValid = true
  }

  public validateTargetingOption(): void {
    this.targetingOption.resetError()
    if (this.targetingFields.every((target) => !target.isActive)) {
      this.targetingOption.isValid = false
      this.targetingOption.errors.push({
        type: ValidationTypes.REQUIRED,
        message: 'You must select at least one targeting option'
      })
    }
  }

  public validateTargetingChoosenOptions(): void {
    this.targetingChoosenOption.resetError()
    const targets = []
    if (!this.segments.validate()) {
      targets.push('Segment')
    }
    if (!this.beacons.validate()) {
      targets.push('Beacon')
    }
    if (!this.geofences.validate()) {
      targets.push('Geofence')
    }
    if (!this.events.validate()) {
      targets.push('Event')
    }

    if (targets.length) {
      this.targetingChoosenOption.isValid = false
      this.targetingChoosenOption.errors.push({
        type: ValidationTypes.REQUIRED,
        message: `You must select at least one ${targets.join(
          ' and one '
        )} or switch ${targets.length === 1 ? 'it' : 'them'} off`
      })
    }
  }

  public validateTargetingDwellTime(): boolean {
    this.targetingDwellTime.resetError()
    if (!this.geofences.validateDwellTime()) {
      this.targetingDwellTime.isValid = false
      this.targetingDwellTime.errors.push({
        type: ValidationTypes.MIN_MAX,
        message: `Enter a whole number between ${MIN_DWELL_TIME} and ${MAX_DWELL_TIME}`
      })

      return false
    }

    return true
  }

  public get registeredFields(): NewRegisteredField[] {
    return [
      this.targetingOption,
      this.targetingChoosenOption,
      this.targetingDwellTime
    ]
  }

  get targetingFields(): [Segments, Beacons, Geofences, Events] {
    return [this.segments, this.beacons, this.geofences, this.events]
  }

  public fillStore<T extends ITargetingPayload>(data: T): void {
    const hasData: boolean[] = []

    hasData.push(this.segments.fillSegments(data.segmentIds))
    hasData.push(this.beacons.fillBeacons(data.beaconEvents))
    hasData.push(
      this.geofences.fillGeofences(
        data.geofenceEvents,
        data.geofenceDwellingTimes
      )
    )
    hasData.push(this.events.fillEvents(data.inAppEventNames))

    if (hasData.some(Boolean)) {
      this.validateStep()
    }
  }

  public getPayload(): ITargetingPayload {
    const { segments, beacons, geofences, events } = this
    return {
      segmentIds: segments.getSelectedIds(),
      beaconIds: beacons.getSelectedIds(),
      geofenceIds: geofences.getSelectedIds(),
      inAppEventNames: events.getSelectedIds(),
      beaconEvents: beacons.getBeaconEvents(),
      geofenceEvents: geofences.getGeofencesEvents(),
      geofenceDwellingTimes: geofences.getGeofenceDwellingTimes()
    }
  }
}

import { faChevronRight, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from 'reactstrap'
import { requestCampaignSendApproval } from '~/api/campaigns'
import { ID } from '~/common.interface'
import Modal from '~/components/modals/Modal/Modal'
import RequestNotificationSendingApproval from '~/components/modals/RequestNotificationSendingApproval'
import SendCampaignModal from '~/components/modals/SendCampaignModal'
import { useStore, withStore } from '~/dataStore'
import useAbortController from '~/hooks/useAbortController'
import ExitCampaign from '~/pages/CampaignBuilder/Email/ExitCampaign'
import {
  clearNotifications,
  NotificationType,
  showNotification
} from '~/utils/Notification'
import BuilderErrors from './BuilderErrors'
import EmailErrors from './EmailErrors'

interface IProps {
  beforeExitStep?: () => void | null
  setBeforeExitStep: (action: any) => void
  className?: string
}

function BottomActions({
  beforeExitStep,
  setBeforeExitStep,
  className
}: IProps) {
  const {
    app: {
      currentAdmin: { isDemoAdmin }
    },
    goTo,
    ui: { showModal, unregisterAllModals },
    campaign: emailBuilder,
    campaign: {
      isSendedOrScheduled,
      isAutosaveActive,
      hasChanged,
      nextStep,
      status,
      currentStep,
      isFinalStep,
      saveCampaign,
      autoSaveCampaign,
      forceShowTooltip,
      isCampaignSaving,
      setForceShowTooltip,
      deliver: { startNow }
    }
  } = useStore()

  const { appId } = useParams<{ appId: ID }>()

  const abortController = useAbortController()

  const [isLoading, setIsLoading] = useState(false)

  const goToCampaignsList = () => {
    emailBuilder.setHasChanged(false)
    clearNotifications(NotificationType.ERROR)
    unregisterAllModals()
    goTo('campaignList', { appId })
  }

  const showErrors = (error: Error & { body: any }) => {
    if (error.message === 'validation') {
      unregisterAllModals()
    } else {
      showNotification(
        <EmailErrors errors={error.body?.errors || error.body?.details} />,
        NotificationType.ERROR
      )
    }
  }

  const saveAsDraft = async () => {
    setIsLoading(true)
    try {
      await autoSaveCampaign(appId)
    } catch (error) {
      console.error(error)
      if (error.message !== 'validation') {
        showErrors(error)
        throw new Error()
      }
    } finally {
      setIsLoading(false)
    }
  }

  const isStepValid = () => currentStep.store.isStepValid

  const handleExit = async () => {
    if (isAutosaveActive) {
      await saveAsDraft()
    }

    if (hasChanged && isSendedOrScheduled) {
      showModal('exitCampaignBuilder')
      return
    }

    goToCampaignsList()
  }

  const onNextStep = async () => {
    if (isDemoAdmin && isFinalStep) {
      showModal(`requestNotificationSendingApproval`, {
        requestSend: async () => {
          try {
            await requestCampaignSendApproval(appId, {
              campaignId: emailBuilder.campaignId
            })
            showNotification(
              'Campaign successfully sent for approval.',
              NotificationType.SUCCESS
            )
            goToCampaignsList()
          } catch (error) {
            console.log(error)
          }
        }
      })
      return
    }

    currentStep.store.validateStep()

    if (!isStepValid()) {
      setForceShowTooltip(true)
      return
    }

    if (beforeExitStep != null) {
      await beforeExitStep()
      setBeforeExitStep(null)
    }

    setForceShowTooltip(false)
    if (isFinalStep) {
      showModal('sendCampaignModal')
    } else {
      if (isAutosaveActive || !status) await saveAsDraft()
      nextStep()
    }
  }

  const getNextBtn = () => {
    if (isFinalStep) {
      if (isDemoAdmin) {
        return 'Request to Send'
      }
      if (!startNow.value) {
        return 'Schedule Delivery'
      }
      return 'Send Campaign'
    }

    if (isAutosaveActive || !status) {
      return 'Save & Continue'
    }

    return 'Next'
  }

  return (
    <>
      <div className={cn('d-flex justify-content-end', className)}>
        <Button
          color=""
          className="bg-white px-4 btn--hover btn-outline-body"
          onClick={handleExit}>
          {isAutosaveActive ? 'Finish later' : 'Exit'}
        </Button>
        <Button
          id="next-btn"
          color="body"
          disabled={isLoading}
          className={cn(
            { disabled: !isStepValid() || isLoading },
            'ms-2 px-4 d-flex align-items-center border- btn--hover'
          )}
          onClick={onNextStep}>
          {isCampaignSaving && (
            <FontAwesomeIcon className="me-2" icon={faSpinner} spin size="xs" />
          )}
          {getNextBtn()}
          <FontAwesomeIcon className="ms-2" icon={faChevronRight} />
        </Button>
        {currentStep.store.getErrorMessages().length > 0 && (
          <BuilderErrors
            key={`${
              currentStep.store.getErrorMessages()?.length
            }${forceShowTooltip}`}
            targetId="next-btn"
            messages={currentStep.store.getErrorMessages()}
            close={() => {
              setForceShowTooltip(false)
            }}
            isOpen={forceShowTooltip}
          />
        )}
      </div>

      <Modal
        id="sendCampaignModal"
        size="md"
        className="send-campaign-modal"
        renderHeader={() => 'You’re about to activate this campaign'}
        render={(close: () => void) => (
          <SendCampaignModal
            onSubmit={() =>
              saveCampaign(
                appId,
                false,
                abortController.getAbortController('sendEmailCampaign').signal
              ).then(goToCampaignsList)
            }
            startAt={emailBuilder.deliver.startAt.value}
            startNow={emailBuilder.deliver.startNow.value}
            targeting={emailBuilder.targeting}
            showErrors={showErrors}
            onClose={close}
          />
        )}
      />

      <RequestNotificationSendingApproval notificationType="campaign" />
      <Modal
        id="exitCampaignBuilder"
        renderHeader={() => 'Exit Campaign Builder'}
        render={(close: () => void) => (
          <ExitCampaign onSubmit={goToCampaignsList} onClose={close} />
        )}
      />
    </>
  )
}

export default withStore(BottomActions)

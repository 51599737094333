import { Col, Row } from 'reactstrap'
import { useEffect, PropsWithChildren } from 'react'
import AllMobileDevicesPreviews from '~/pages/Campaign/Notification/Preview/AllMobileDevicesPreview'
import AlertNotification from '~/pages/Campaign/Notification/AlertNotification/Model/AlertNotification'
import MobileCampaignPanel from '~/pages/CampaignBuilder/MobileCampaignPanel/MobileCampaignPanel'
import AlertVariantStep from './AlertVariantStep'
import { CollapseContextProvider } from '~/components/CollapseWithToggle/CollapseWithToggle.context'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import { useStore, withStore } from '~/dataStore'

interface IProps {
  alertNotification: AlertNotification
  onlyCarousel?: boolean
  isCardCampaign?: boolean
  isSended?: boolean
  colsWidth?: {
    left: { xxl: { size: number } }
    right: { xxl: { size: number } }
  }
  withGoals?: boolean
}

const AlertNotificationBuilder = ({
  alertNotification,
  onlyCarousel = false,
  isCardCampaign = false,
  isSended = false,
  colsWidth,
  children,
  withGoals = false
}: PropsWithChildren<IProps>): React.ReactElement | null => {
  const {
    app: { currentApp }
  } = useStore()

  useEffect(() => {
    alertNotification.shared
      .fetchMergeTags(currentApp.id)
      .catch((error) => console.error(error))
  }, [currentApp.id])

  if (!alertNotification.currentVariant) {
    return null
  }

  const fieldsDictionary = {
    headline: 'title',
    text: 'notification'
  }

  return (
    <CollapseContextProvider fieldMapper={fieldsDictionary}>
      <Row className="align-items-start">
        <Col
          widths={['md', 'xxl']}
          md={{ size: 7 }}
          {...colsWidth?.left}
          className="mb-4">
          <AllMobileDevicesPreviews
            onlyCarousel={onlyCarousel}
            parts={
              alertNotification.generateCurrentVariantPreview().push.variants[0]
            }
            campaignType={CampaignType.PUSH}
          />
        </Col>
        <Col widths={['md', 'xxl']} md={{ size: 5 }} {...colsWidth?.right}>
          {children}
          <MobileCampaignPanel>
            <AlertVariantStep
              alertNotification={alertNotification}
              isCardCampaign={isCardCampaign}
              isSended={isSended}
              withGoals={withGoals}
            />
          </MobileCampaignPanel>
        </Col>
      </Row>
    </CollapseContextProvider>
  )
}

export default withStore(AlertNotificationBuilder)

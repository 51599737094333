import React from 'react'
import { Row } from 'reactstrap'
import { ISummary } from '~/dataStore/reports/Report.interface'
import NumberStat from '~/components/reports/NumberStat'
import Progress from '~/components/reports/Progress'

interface IProps {
  numbers: ISummary[]
}

export default React.memo(function CampaignNumbers({
  numbers
}: IProps): React.ReactElement {
  return (
    <Row>
      {numbers.map((number) => (
        <NumberStat
          key={number.label}
          tooltip={number.tooltip}
          title={number.label}
          value={number.content}
          disabled={number.disabled}
          col={{ md: 6, xl: '' }}>
          {!number.disabled && (
            <Progress
              rateTooltip={number.rateTooltip}
              rate={number.rate}
              rateLabel={number.rateLabel}
            />
          )}
        </NumberStat>
      ))}
    </Row>
  )
})

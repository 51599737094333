import React, { PropsWithChildren } from 'react'
import cn from 'classnames'
import { Col } from 'reactstrap'

import StatCard from '~/components/reports/StatCard'

interface IProps {
  tooltip?: string
  title?: string
  value?: number | string | React.ReactElement | null
  col: any
  classes?: string
  icon?: React.ReactElement
  disabled?: boolean
}

export default function NumberStat({
  tooltip,
  title,
  value,
  col,
  children,
  classes,
  disabled = false,
  icon
}: PropsWithChildren<IProps>): React.ReactElement {
  return (
    <Col {...col} className="mb-4 reports--number">
      <StatCard
        tooltip={tooltip}
        title={title}
        titleTag="h3"
        disabled={disabled}
        text={disabled ? '' : value}
        icon={icon}
        cardClasses="h-100 mb-0"
        cardBodyClasses="p-0 pt-4"
        classes={cn(
          classes,
          'd-flex flex-column justify-content-between text-center h-100 align-items-center pt-3'
        )}>
        {children && <div className="w-100">{children}</div>}
      </StatCard>
    </Col>
  )
}

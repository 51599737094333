import React from 'react'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import ApplicationDetailsSection from './sections/ApplicationDetailsSection'
import FirebaseSection from './sections/FirebaseSection'
import ApplePushSection from './sections/ApplePushSection'
import SDKSection from './sections/SDKSection'
import GlobalSettingsSection from './sections/GlobalSettingsSection'
import WebhookSection from './sections/WebhookSection'
import WebSDKSection from './sections/WebSDKSection'
import { useStore, withStore } from '~/dataStore'
import FCMSection from './sections/FirebaseSection/FCMSection'
import BannoSettingSection from './sections/BannoSettings'

const AppSettings = (): React.ReactElement => {
  const {
    app: {
      appDetails: { featureFlags }
    }
  } = useStore()

  return (
    <div className="page-wrapper pb-5">
      <Header className="mb-4">
        <HeaderTitle>App Settings</HeaderTitle>
      </Header>
      <ApplicationDetailsSection />
      {!featureFlags.fcmV1CredsUploader && <FirebaseSection />}
      {featureFlags.fcmV1CredsUploader && <FCMSection />}
      {featureFlags.bannoPushSendToUrl && <BannoSettingSection />}
      <ApplePushSection />
      <SDKSection />
      <GlobalSettingsSection />
      <WebhookSection />
      <WebSDKSection />
    </div>
  )
}

export default withStore(AppSettings)

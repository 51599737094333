import cn from 'classnames'
import { useEffect } from 'react'
import { Col, Row } from 'reactstrap'

import { useParams } from 'react-router-dom'
import setUpCampaignInfo from '~/assets/img/campaign-builder/Blank_canvas_for_Set_up.svg'
import Input from '~/components/forms/Input/Input'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import Img from '~/components/Img'
import { useStore, withStore } from '~/dataStore'

import { getMainCampaignType } from '~/pages/Campaign/Notification/Notification.service'
import SeparatorLine from '../../../components/SeparatorLine'
import useJourneyStore from '../NotificationBuilder.context'
import ControlGroupForm from './ControlGroupForm'
import GoalForms from './GoalForms/GoalForms'
import { stepMedia } from './style.scss'

function SetupStep() {
  const {
    app: {
      appDetails: {
        featureFlags: { campaignsGoals }
      }
    },
    campaign: {
      campaignType,
      setUp: { fetchEvents, searchQuery, setControlGroupActive }
    }
  } = useStore()
  const { appId } = useParams<{ appId: string }>()
  const store = useJourneyStore()

  useEffect(() => {
    fetchEvents(appId, getMainCampaignType(campaignType, 'campaignBuilder'))
  }, [appId, fetchEvents, searchQuery])

  useEffect(() => {
    const cg = sessionStorage.getItem('cg')
    if (cg) {
      setTimeout(() => {
        setControlGroupActive(true)
      }, 450)
      sessionStorage.removeItem('cg')
    }
  }, [])

  return (
    <Row>
      <Col
        md={{ size: campaignsGoals ? 4 : 3, offset: campaignsGoals ? 2 : 4 }}>
        <Header>
          <HeaderTitle>Set up Campaign</HeaderTitle>
        </Header>
        <Input
          label="Campaign Name"
          name="campaignName"
          type="text"
          placeholder="Enter campaign name"
          value={store.entry.name.value}
          onChange={(e) => {
            store.entry.setName(e.target.value)
          }}
          onBlur={store.entry.validateName}
          invalid={!store.entry.name.isValid}
        />
        {campaignsGoals && (
          <>
            <GoalForms />
            <SeparatorLine />
            <ControlGroupForm />
          </>
        )}
      </Col>
      <Col
        md={{ size: campaignsGoals ? 5 : 3, offset: campaignsGoals ? 0 : 4 }}>
        <Img
          src={setUpCampaignInfo}
          className={cn(stepMedia, 'img-fluid', {
            'mt-5': !campaignsGoals
          })}
        />
      </Col>
    </Row>
  )
}

export default withStore(SetupStep)

import React from 'react'
import LineChart from '~/components/ApexCharts/ApexLineChart'
import StatCard from '~/components/reports/StatCard'
import { IChartData } from '~/dataStore/reports/Report.interface'

interface IProps {
  charts: IChartData[]
  disabled?: boolean
}

export default React.memo(function CampaignPerformance({
  charts,
  disabled = false
}: IProps): React.ReactElement {
  return (
    <StatCard title="Campaign Performance" disabled={disabled}>
      <LineChart chartData={charts} />
    </StatCard>
  )
})

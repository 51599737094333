import { useEffect } from 'react'
import { Container } from 'reactstrap'

import cn from 'classnames'
import { uniqueId } from 'lodash'
import { useParams } from 'react-router-dom'
import { useStore, withStore } from '~/dataStore'
import useExitPrompt from '~/hooks/useExitPrompt'

import Steps from '~/components/BuilderSteps'
import Modal from '~/components/modals/Modal'
import CampaignBuilderStatus from '~/components/Navbar/BuilderStatus'
import { InAppSize } from '~/dataStore/Campaign/Campaign.interface'
import { getObjectFromSearchParams, useQuery } from '~/utils/utilities'
import { JourneyBlockType } from '../Journeys/JourneyBuilder/Store/JourneyBuilder.interface'
import BottomActions from './BottomActions'
import CurrentStepComponent from './CurrentStepComponent'
import ExitCampaign from './Email/ExitCampaign'
import useJourneyStore from './NotificationBuilder.context'
import './styles.scss'

const blankAlertTemplate = {
  id: 'blankAlertTemplate',
  name: 'Create Push Campaign',
  description:
    'Build your Journey from scratch. Be a master architect behind all notifications and split queries. Decide what notification channels will be best for your audience and add new auto segments which help you in future campaigns.',
  largeImage:
    'https://pulsate-campaign-attachments-production.s3.eu-west-1.amazonaws.com/5fa13b64636f6e4be81b0000/2023-05-25/1685006327_blank_journey.png',
  smallImage:
    'https://pulsate-campaign-attachments-production.s3.eu-west-1.amazonaws.com/5fa13b64636f6e4be81b0000/2023-06-05/1685967348_CreatJourney.png',
  diagram: [
    {
      childBlocks: [{ type: JourneyBlockType.EXIT }],
      type: JourneyBlockType.MESSAGE,
      name: 'Message',
      options: [
        {
          position: 0,
          id: uniqueId(),
          notifications: [
            {
              id: null,
              position: 0
            }
          ],
          type: 'alert',
          notification: undefined,
          isValid: true
        }
      ]
    }
  ]
}

function NotificationBuilder() {
  const prompt = useExitPrompt()

  const {
    app: { openCurtain },
    goTo
  } = useStore()

  const store = useJourneyStore()

  const { appId, campaignId } = useParams<{
    appId: string
    campaignId: string
    size: InAppSize
  }>()

  const query = useQuery()

  useEffect(() => {
    async function setBuilder(): Promise<void> {
      if (campaignId) {
        try {
          await store.fetch(campaignId).catch((error) => {
            if (error.status >= 400 && error.status < 500) {
              goTo('alertCampaignBuilder', { appId })
            } else {
              console.error(error)
            }
          })
        } catch (e) {
          console.error(e)
        }
      } else {
        store.initTemplate(blankAlertTemplate)
      }
    }
    setBuilder().then(() => {
      const queryParams = getObjectFromSearchParams(query)
      if (queryParams.step) {
        store.steps?.setStepByNumber(Number(queryParams.step))
      }
      openCurtain()
    })
  }, [openCurtain])

  useEffect(() => {
    const queryParams = getObjectFromSearchParams(query)
    if (queryParams.step) {
      store.steps?.setStepByNumber(Number(queryParams.step))
    } else {
      store.steps?.setStepByNumber(1)
    }
  }, [query.toString(), store.steps])

  if (!store.journeyIsInitialized) {
    return null
  }

  return (
    <>
      <CampaignBuilderStatus
        autoSave
        loading={false}
        exitRoute="campaignList"
        name={store.entry.name.value}
        status={store.entry.status}
      />
      <Container className={cn('step-content-wrapper pb-6')}>
        <CurrentStepComponent
          name={store.steps?.getStepNameByNumber(
            store.steps?.currentStep?.number || 1
          )}
        />
      </Container>
      <div className="d-flex justify-content-between campaign-footer campaign-footer--shrink">
        <Steps
          currentStep={store.steps?.currentStep}
          isLoading={false}
          steps={store.steps?.steps}
          onStepClick={(stepNumber) => {
            store.entry.resetError()
            goTo(undefined, {}, { step: stepNumber })
          }}
        />
        <BottomActions className="ms-3" />
      </div>
      <Modal
        id="confirmExitCampaignModal"
        renderHeader={() => 'Exit Campaign Builder'}
        render={(close: () => void) => (
          <ExitCampaign
            onSubmit={() => {
              close()
              prompt.setConfirmedNavigation(true)
            }}
            onClose={close}
          />
        )}
      />
    </>
  )
}

export default withStore(NotificationBuilder)

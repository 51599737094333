import React from 'react'
import cn from 'classnames'
import emailCampaignIcon from '~/components/icons/emailCampaignIcon.svg'
import pushCampaignIcon from '~/components/icons/pushCampaignIcon.svg'
import smsCampaignIcon from '~/components/icons/smsCampaignIcon.svg'
import inappCampaignIcon from '~/components/icons/inappCampaignIcon.svg'
import cardCampaignIcon from '~/components/icons/pushCardCampaignIcon.svg'
import cardFeedCampaignIcon from '~/components/icons/cardFeedCampaignIcon.svg'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import AnimatedEmailIcon from './animatedIcons/Email'
import AnimatedSmsIcon from './animatedIcons/SMS'
import AnimatedCardIcon from './animatedIcons/Card'
import AnimatedCardAndNotificationIcon from './animatedIcons/CardAndNotification'
import AnimatedPushIcon from './animatedIcons/Push'
import AnimatedInAppIcon from './animatedIcons/InApp'

interface IProps {
  type?: CampaignType | 'notificationCard'
  className?: string
}

export default function CampaignIcon({
  type,
  className
}: IProps): React.ReactElement | null {
  switch (type) {
    case CampaignType.EMAIL:
      return (
        <>
          <img
            className={cn(className, 'group-hover:hidden')}
            src={emailCampaignIcon}
            alt="Email campaign"
          />
          <AnimatedEmailIcon
            className={cn(className, 'd-none group-hover:block')}
          />
        </>
      )
    case CampaignType.PUSH:
    case CampaignType.ALERT:
      return (
        <div>
          <img
            className={cn(className, 'group-hover:hidden')}
            src={pushCampaignIcon}
            alt="Push campaign"
          />
          <AnimatedPushIcon
            className={cn(className, 'd-none group-hover:block')}
          />
        </div>
      )
    case CampaignType.SMS:
      return (
        <div>
          <img
            className={cn(className, 'group-hover:hidden')}
            src={smsCampaignIcon}
            alt="SMS campaign"
          />
          <AnimatedSmsIcon
            className={cn(className, 'd-none group-hover:block')}
          />
        </div>
      )
    case CampaignType.IN_APP:
      return (
        <div>
          <img
            className={cn(className, 'group-hover:hidden')}
            src={inappCampaignIcon}
            alt="InApp campaign"
          />
          <AnimatedInAppIcon
            className={cn(className, 'd-none group-hover:block')}
          />
        </div>
      )
    case CampaignType.CARD:
      return (
        <div>
          <img
            className={cn(className, 'group-hover:hidden')}
            src={cardCampaignIcon}
            alt="Card campaign"
          />
          <AnimatedCardIcon
            className={cn(className, 'd-none group-hover:block')}
          />
        </div>
      )
    case 'notificationCard':
      return (
        <div>
          <img
            className={cn(className, 'group-hover:hidden')}
            src={cardFeedCampaignIcon}
            alt="Notification with a feed post"
          />
          <AnimatedCardAndNotificationIcon className="d-none group-hover:block" />
        </div>
      )
    default:
      break
  }

  return null
}

import React from 'react'
import JourneyReview from './JourneyReview'
import useJourneyStore from '~/pages/NotificationBuilder/NotificationBuilder.context'
import { getChildrenByType } from '~/pages/Journeys/Journey.service'
import { JourneyBlockType } from '~/pages/Journeys/JourneyBuilder/Store/JourneyBuilder.interface'

function ReviewStep(): React.ReactElement {
  const store = useJourneyStore()

  return (
    <JourneyReview
      startAt={store.entry.startAt.value}
      endAt={store.entry.endAtActive ? store.entry.endAt.value : null}
      timeZoneName={store.entry.timeZoneName.value}
      segments={store.entry.targeting.segments}
      geofences={store.entry.targeting.geofences}
      beacons={store.entry.targeting.beacons}
      status={store.entry.status}
      messages={
        getChildrenByType([JourneyBlockType.MESSAGE], store.entry) || []
      }
    />
  )
}

export default ReviewStep

import {
  CampaignNotificationType,
  CampaignType
} from '../Campaign/Campaign.interface'
import { Trigger } from './EmailBuilder.interface'

export const MFieldsToInputsName = new Map<string, string>([
  ['goals', 'Goals'],
  ['beacons', 'Beacons'],
  ['delivery', 'Deliver'],
  ['emailNotification', 'Template'],
  ['expiryTimeFrame', 'Expiry time frame'],
  ['expiryTimeValue', 'Expiry time value'],
  ['geofences', 'Geofences'],
  ['inAppEventNames', 'Events'],
  ['timeFrame', 'Time frame'],
  ['timeWindows', 'Delivery on certain days'],
  ['segmentIds', 'Targeting options'],
  ['bodyTemplate', 'Notification Text'],
  ['subtitleTemplate', 'Sub-title'],
  ['titleTemplate', 'Title'],
  ['url', 'Attachment']
])

export const MTrigger = new Map<Trigger, string>([
  [Trigger.ENTER, 'Entering'],
  [Trigger.EXIT, 'Exiting'],
  [Trigger.DWELL, 'Dwell Time'],
  [Trigger.TRIGGERED, 'Triggered']
])

export const MCampaignToNotification = new Map<
  CampaignType,
  CampaignNotificationType
>([
  [CampaignType.EMAIL, CampaignNotificationType.EMAIL_NOTIFICATION_CAMPAIGN],
  [CampaignType.SMS, CampaignNotificationType.SMS_NOTIFICATION_CAMPAIGN],
  [CampaignType.IN_APP, CampaignNotificationType.IN_APP_NOTIFICATION],
  [CampaignType.PUSH, CampaignNotificationType.PUSH_NOTIFICATION],
  [CampaignType.ALERT, CampaignNotificationType.ALERT_NOTIFICATION],
  [CampaignType.CARD, CampaignNotificationType.CARD_INBOX]
])

export const MCardCampaignToNotification = new Map<
  CampaignType,
  CampaignNotificationType
>([
  [CampaignType.PUSH, CampaignNotificationType.CARD_PUSH_NOTIFICATIONS],
  [CampaignType.SMS, CampaignNotificationType.CARD_SMS_NOTIFICATION],
  [CampaignType.IN_APP, CampaignNotificationType.CARD_IN_APP]
])

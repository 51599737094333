import { useState } from 'react'
import { Card, Col, Row } from 'reactstrap'

import cn from 'classnames'
import { useStore, withStore } from '~/dataStore'

import { TARGETS } from '~/pages/CampaignBuilder/Email/consts'
import Beacons from './Beacons'
import Geofences from './Geofences'
import Segments from './Segments'

import CollapseWithToggle from '~/components/CollapseWithToggle'
import DisabledFeatureWarning from '~/components/common/DisabledFeatureWarning'
import TargetingPreview from '~/pages/Campaign/UI/TargetingPreview'
import useJourneyStore from '../NotificationBuilder.context'
import { targetWrapper } from './Step5.scss'

const TargetingStep = () => {
  const [openPicker, setOpenPicker] = useState<Exclude<
    TARGETS,
    TARGETS.SUBSCRIPTIONS | TARGETS.IN_APP_EVENTS
  > | null>()
  const {
    app: {
      appDetails: { featureFlags }
    },
    ui
  } = useStore()

  const store = useJourneyStore()

  const { targeting } = store.entry

  const pickerProps = (
    id: Exclude<TARGETS, TARGETS.SUBSCRIPTIONS | TARGETS.IN_APP_EVENTS>
  ) => {
    return {
      name: id,
      disabled:
        (id === TARGETS.BEACONS && !featureFlags.beacons) ||
        (id === TARGETS.GEOFENCES && !featureFlags.geofences),
      opened: openPicker === id,
      isValid: targeting.targetingOption.isValid,
      setOpened: (open: boolean) => {
        setOpenPicker(open ? id : null)
      },
      className: 'px-20p',
      checked: targeting[id].isActive,
      setChecked: (check: boolean) => {
        if (!check) {
          targeting.reset(id)
          targeting.validateStep()
        } else {
          targeting[id].setActive(true)
          targeting.validateTargetingOption()
        }
      }
    }
  }

  return (
    <>
      <Row className="align-items-start">
        <Col
          widths={['md', 'xxl']}
          md={{ size: 6 }}
          xxl={{ size: 5, offset: 2 }}
          className="mb-4">
          <TargetingPreview
            mode={'builder'}
            segments={targeting.segments}
            beacons={targeting.beacons}
            geofences={targeting.geofences}
            events={targeting.events}
          />
        </Col>
        <Col
          widths={['md', 'xxl']}
          md={{ size: 5, offset: 1 }}
          xxl={{ size: 4, offset: 1 }}
          className={cn('sticky', { 'z-index-20': ui.getHintWindowActive() })}>
          <Card className={cn(targetWrapper)}>
            <CollapseWithToggle {...pickerProps(TARGETS.SEGMENTS)}>
              <Segments isSended={false} targeting={targeting} />
            </CollapseWithToggle>

            <CollapseWithToggle {...pickerProps(TARGETS.BEACONS)}>
              {featureFlags.beacons ? (
                <Beacons isSended={false} targeting={targeting} />
              ) : (
                <p className="p-3 text-blue-gray text-13">
                  <DisabledFeatureWarning feature="Beacons" />
                </p>
              )}
            </CollapseWithToggle>

            <CollapseWithToggle {...pickerProps(TARGETS.GEOFENCES)}>
              {featureFlags.geofences ? (
                <Geofences isSended={false} targeting={targeting} />
              ) : (
                <p className="p-3 text-blue-gray text-13">
                  <DisabledFeatureWarning feature="Geofences" />
                </p>
              )}
            </CollapseWithToggle>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default withStore(TargetingStep)

import { useState, useEffect, CSSProperties } from 'react'
import { Container } from 'reactstrap'
import cn from 'classnames'
import { withStore, useStore } from '~/dataStore'
import Link from '~/components/Link'
import routes from '~/routes'
import CampaignTile from './CampaignTile'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import { SingleRoute } from '~/dataStore/Store.interface'
import InAppSize from './InAppSize'
import CardNotificationType from './CardNotificationType'
import { NotificationTypeTile } from './CampaignSubtile'
import { isString } from '~/utils/utilities'
import { get } from '~/api/v2'
import { NotificationType, showNotification } from '~/utils/Notification'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'
import RegisterInterestModal from '../UI/RegisterInterestModal'

type Tile = {
  name: string
  flag: boolean
  campaignType: CampaignType | 'notificationCard'
  route: string | SingleRoute
  params: Record<string, string>
}

const screens = {
  notificationCard: 1,
  [CampaignType.IN_APP]: 2
}

function NewCampaign() {
  const {
    app,
    ui: { showModal, unregisterModal }
  } = useStore()

  const [campaignTypes, setCampaignTypes] = useState<Tile[]>([])

  const [additionalNotificationTypes, setAdditionalNotificationTypes] =
    useState<NotificationTypeTile[]>([])

  const [selected, setSelected] = useState<{
    current: 'notificationCard' | CampaignType.IN_APP | undefined
    history: Array<'notificationCard' | CampaignType.IN_APP | undefined>
  }>({ current: undefined, history: [] })

  const [params, setParams] = useState<Record<string, string> | undefined>(
    undefined
  )

  async function register(campaignType: CampaignType, name: string) {
    try {
      await get(`${app.currentApp.id}/campaigns/register_interest_in`, {
        type: campaignType
      })
      showModal(`successfulRegisterInterest`, { name })
      unregisterModal(`registerInterestModal`)
      setTimeout(() => {
        unregisterModal(`successfulRegisterInterest`)
      }, 5000)
    } catch (error) {
      showNotification(
        'Something went wrong, please try again later.',
        NotificationType.ERROR
      )
      console.error(error)
    }
  }

  function handleRegisterClick({
    newType,
    name,
    campaignType
  }: {
    newType: boolean
    name: string
    campaignType: CampaignType
  }) {
    showModal(`registerInterestModal`, {
      register: () => register(campaignType, name),
      newType,
      name
    })
  }

  function goBack(): void {
    setSelected((prev) => {
      if (!prev?.history.length) {
        return { current: undefined, history: [] }
      }

      const newHistory = [...prev.history]
      return {
        current: newHistory.pop(),
        history: newHistory
      }
    })
  }

  function goNext(
    campaignType: CampaignType.CARD | CampaignType.IN_APP,
    newParams: Record<string, string>
  ): void {
    setSelected((prev) => {
      const newHistory = [...prev.history]
      if (prev.current) {
        newHistory.push(prev.current)
      }

      return {
        current: campaignType,
        history: newHistory
      }
    })

    setParams(newParams)
  }

  function calculateTranslation(): CSSProperties {
    if (!selected.current) {
      return {}
    }
    const position = screens[selected.current]
    return {
      transform: `translateX(-${position * 100}%)`
    }
  }

  useEffect(() => {
    const mainTypes = [
      {
        name: 'Push',
        description: (
          <>
            Appears outside the app on the mobile device screen{' '}
            <p className="mt-2 mb-0 font-weight-semibold">
              User Opt-in is required
            </p>
          </>
        ),
        flag: !!app.appDetails?.featureFlags.push,
        campaignType: CampaignType.PUSH,
        route: routes.campaignBuilder,
        params: { appId: app.currentApp.id, campaignType: CampaignType.PUSH }
      },
      {
        name: 'Push',
        description: (
          <>
            Appears outside the app on the mobile device screen{' '}
            <p className="mt-2 mb-0 font-weight-semibold">
              User Opt-in is required
            </p>
          </>
        ),
        flag: !!app.appDetails?.featureFlags.bannoPushSendToUrl,
        campaignType: CampaignType.ALERT,
        route: routes.alertCampaignBuilder,
        params: { appId: app.currentApp.id, campaignType: CampaignType.ALERT }
      },
      {
        name: 'In-App',
        description: 'Appears the next time the user logs into the App',
        flag: true,
        campaignType: CampaignType.IN_APP,
        route: routes.campaignBuilder,
        params: { appId: app.currentApp.id, campaignType: CampaignType.IN_APP }
      },
      {
        name: MCampaignTypeToName.get(CampaignType.CARD),
        description: 'Appears in the feed',
        flag: !!app.appDetails?.featureFlags.card,
        campaignType: CampaignType.CARD,
        route: routes.campaignBuilder,
        params: { appId: app.currentApp.id, campaignType: CampaignType.CARD }
      },
      {
        name: (
          <>
            Notification <span className="text-muted">+ </span>
            {MCampaignTypeToName.get(CampaignType.CARD)}
          </>
        ),
        description: 'Notification with a feed post',
        flag:
          !!app.appDetails?.featureFlags.card &&
          !!app.appDetails?.featureFlags.feedPostPlusNotification,
        campaignType: 'notificationCard',
        route: routes.campaignBuilder,
        params: { appId: app.currentApp.id, campaignType: CampaignType.CARD }
      },
      {
        name: 'Email',
        flag: !!app.appDetails?.featureFlags.email,
        route: !app.appDetails?.featureFlags.email
          ? 'https://info.pulsatehq.com/pulsate-overview'
          : routes.campaignBuilder,
        campaignType: CampaignType.EMAIL,
        params: !app.appDetails?.featureFlags.email
          ? {}
          : { appId: app.currentApp.id, campaignType: CampaignType.EMAIL }
      },
      {
        name: 'SMS',
        flag: !!app.appDetails?.featureFlags.sms,
        route: !app.appDetails?.featureFlags.sms ? '/' : routes.campaignBuilder,
        campaignType: CampaignType.SMS,
        params: !app.appDetails?.featureFlags.sms
          ? {}
          : { appId: app.currentApp.id, campaignType: CampaignType.SMS }
      }
    ]

    const notificationTypes = [
      {
        name: 'Push',
        description: 'This is the most standard way to notify users.',
        route: routes.campaignBuilder,
        isDisabled: !app.appDetails?.featureFlags.push,
        campaignType: CampaignType.PUSH,
        params: {
          appId: app.currentApp.id,
          campaignType: CampaignType.CARD,
          notificationType: CampaignType.PUSH
        }
      },
      {
        name: 'In-App',
        description: 'Appears the next time the user logs into the App',
        route: routes.campaignBuilder,
        campaignType: CampaignType.IN_APP,
        onClick: () =>
          goNext(CampaignType.IN_APP, {
            appId: app.currentApp.id,
            campaignType: CampaignType.CARD,
            notificationType: CampaignType.IN_APP
          })
      },
      {
        name: 'SMS',
        description:
          'Delivers notification to your users mobile phone as a SMS',
        route: routes.campaignBuilder,
        campaignType: CampaignType.SMS,
        isDisabled: !app.appDetails?.featureFlags.sms,
        onClick: !app.appDetails?.featureFlags.sms
          ? () => {
              handleRegisterClick({
                newType: false,
                name: 'SMS',
                campaignType: CampaignType.SMS
              })
            }
          : undefined,
        params: {
          appId: app.currentApp.id,
          campaignType: CampaignType.CARD,
          notificationType: CampaignType.SMS
        }
      }
    ]

    setCampaignTypes(mainTypes)
    setAdditionalNotificationTypes(notificationTypes)
  }, [app.appDetails, app.currentApp])

  return (
    <>
      <Container className="step-content-wrapper pb-6">
        <div className="d-flex justify-content-center">
          <div className="builder d-flex">
            <div
              style={calculateTranslation()}
              className={cn('builder__item', {
                'builder__item--hidden': selected.current
              })}>
              <p className="builder__header">Choose Campaign Type</p>
              <div className="builder__grid" data-testid="tile-grid">
                {campaignTypes.map((type) => {
                  if (
                    !type.flag &&
                    ![CampaignType.EMAIL].includes(type.campaignType)
                  ) {
                    return null
                  }

                  if (
                    [CampaignType.IN_APP, 'notificationCard'].includes(
                      type.campaignType
                    )
                  ) {
                    return (
                      <CampaignTile
                        key={type.name}
                        {...type}
                        onClick={() => goNext(type.campaignType, type.params)}
                      />
                    )
                  }

                  const registerInterest =
                    CampaignType.EMAIL === type.campaignType && !type.flag

                  // const comingSoon =
                  //   CampaignType.SMS === type.campaignType && !type.flag

                  return (
                    // eslint-disable-next-line
                    <Link
                      tabIndex={-1}
                      key={type.name}
                      route={type.route}
                      params={type.params}
                      target={isString(type.route) ? '_blank' : ''}
                      className="text-decoration-none">
                      <CampaignTile
                        {...type}
                        registerInterest={registerInterest}
                        // comingSoon={comingSoon}
                        handleRegisterInterest={handleRegisterClick}
                      />
                    </Link>
                  )
                })}
              </div>
            </div>
            <div
              data-testid="card-notification"
              style={calculateTranslation()}
              className={cn('builder__item builder__item--hidden', {
                'builder__item--shown': selected.current === 'notificationCard'
              })}>
              <CardNotificationType
                goBack={goBack}
                types={additionalNotificationTypes}
              />
            </div>
            <div
              data-testid="inapp-size"
              style={calculateTranslation()}
              className={cn('builder__item builder__item--hidden', {
                'builder__item--shown': selected.current === CampaignType.IN_APP
              })}>
              <InAppSize
                goBack={goBack}
                route={routes.campaignBuilder}
                params={params}
              />
            </div>
          </div>
        </div>
      </Container>
      <RegisterInterestModal />
    </>
  )
}

export default withStore(NewCampaign)

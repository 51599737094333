import { makeAutoObservable } from 'mobx'
import { getMainCampaignType } from '~/pages/Campaign/Notification/Notification.service'
import { IStep } from '../emailBuilder/EmailBuilder.interface'
import AlertCampaign from './AlertCampaign'
import {
  CampaignType,
  ICurrentStep,
  IStepStore,
  StepStoreFactory
} from './Campaign.interface'

export default class Steps {
  public stepStores: StepStoreFactory[] = []

  public currentStep: ICurrentStep | undefined = undefined

  constructor(stepStores: StepStoreFactory[] = []) {
    makeAutoObservable(this, undefined, { autoBind: true })
    this.stepStores = stepStores
    this.currentStep = {
      number: 1,
      store: this.stepStores[0].store
    }
  }

  public static initStores(campaignTypes: CampaignType[], source: any): Steps {
    switch (getMainCampaignType(campaignTypes, 'campaignBuilder')) {
      //   case CampaignType.EMAIL:
      //     this.stepStores = EmailCampaign.createStepStores(this)
      //     break
      //   case CampaignType.SMS:
      //     this.stepStores = SMSCampaign.createStepStores(this)
      //     break
      //   case CampaignType.IN_APP:
      //     this.stepStores = InAppCampaign.createStepStores(this)
      //     break
      //   case CampaignType.PUSH:
      //     this.stepStores = PushCampaign.createStepStores(this)
      //     break
      case CampaignType.ALERT:
        return new Steps(AlertCampaign.createStepStores(source))
      //   case CampaignType.CARD:
      //     this.stepStores = CardCampaign.createStepStores(
      //       this,
      //       getAdditionalCampaignType(campaignTypes, 'campaignBuilder')
      //     )
      //     break
      default:
        return new Steps()
    }
  }

  private getStoreByName<T extends IStepStore>(name: string): T {
    return this.stepStores.find((store) => store.name === name)?.store as T
  }

  private getStoreByNumber<T extends IStepStore>(number: number): T {
    return this.stepStores.find((store) => store.position === number)
      ?.store as T
  }

  public getStepNameByNumber(number: number): string | undefined {
    return this.stepStores.find((store) => store.position === number)?.name
  }

  private setStep(step: ICurrentStep): void {
    this.currentStep = step
  }

  public setStepByNumber(number: number): void {
    this.setStep({
      number,
      store: this.getStoreByNumber(number)
    })
  }

  get steps(): IStep[] {
    return this.stepStores.map((step) => ({
      number: step.position,
      title: step.displayName,
      isValid: step.name !== 'deliver',
      beenValid: true
    }))
  }
}

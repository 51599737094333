import React from 'react'
import cn from 'classnames'

import Skeleton from 'react-loading-skeleton'
import { JourneyStatus } from '~/pages/Journeys/Journeys.interface'
import JourneyTemplateIcon from '~/pages/Journeys/UI/JourneyTemplateIcon/JourneyTemplateIcon'
import { withStore } from '~/dataStore'

import Start from '~/pages/Campaign/CampaignReview/ReviewTopBar/Start'
import { ExpiryType } from '~/dataStore/emailBuilder/EmailBuilder.interface'

interface IProps {
  isDetailsInPast: boolean
  deliver: {
    isCampaignStartingEndingSameDay: boolean
    startAt: Date | string | undefined | null
    endAt: Date | string | undefined | null
    startNow?: boolean | string
    expiryType?: ExpiryType
    expiryDate?: Date | string | undefined
    expiryTimeFrame?: string
    expiryTimeValue?: string | number | null
    campaignExpiry?: boolean
    isDeliveryFuture: boolean
  }
  status?: JourneyStatus
}

function ReviewTopBar({ isDetailsInPast, deliver, status }: IProps) {
  return (
    <header className={cn('campaign-header d-flex justify-content-end w-100')}>
      <div className="campaign-header__col d-flex flex-column">
        <Start
          isDetailsInPast={isDetailsInPast}
          deliver={deliver}
          type="Journey"
          status={status}
        />
      </div>
    </header>
  )
}

export default withStore(ReviewTopBar)

import { makeAutoObservable } from 'mobx'
import {
  NotificationReportDTO,
  NotificationType
} from '~/pages/Campaign/Notification/Notification.interface'
import { fetchNotificationStats } from '~/pages/Campaign/Notification/Notification.connector'
import { ID } from '~/common.interface'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import {
  ChartDataType,
  IChartData,
  ILinkReport,
  ISummary
} from '~/pages/Campaign/CampaignReports/Model/Report.interface'
import { IGoalPerformance, MessageBlock } from './JourneyReport.interface'
import PushReport from '~/pages/Campaign/Notification/Report/Model/PushReport'
import EmailReport from '~/pages/Campaign/Notification/Report/Model/EmailReport'
import InAppReport from '~/pages/Campaign/Notification/Report/Model/InAppReport'
import {
  NotificationType as ToastType,
  showNotification
} from '~/utils/Notification'
import CardReport from '~/pages/Campaign/Notification/Report/Model/CardReport'

type NotificationReports = PushReport | EmailReport | InAppReport | CardReport
export default class JourneyNotificationReport {
  public selectedMessage: MessageBlock | undefined

  public selectedNotification:
    | { notificationId: ID; type: NotificationType }
    | undefined

  public currentReport: NotificationReports | undefined = undefined

  private reports = new Map<ID, NotificationReportDTO>([])

  constructor(private appID: ID) {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  public selectMessage(message: MessageBlock): void {
    if (message.options[0].type) {
      this.selectedMessage = message
      this.selectNotification(
        message.options[0].type === NotificationType.CARD
          ? CampaignType.CARD_FRONT
          : message.options[0].type
      )
    }
  }

  public async selectNotification(
    notificationType: NotificationType
  ): Promise<void> {
    this.setNotification(notificationType)

    if (this.selectedNotification) {
      let response = this.reports.get(this.selectedNotification.notificationId)
      if (!response) {
        response = await this.fetchStats()
      }
      this.createReport(response, notificationType)
    }
  }

  private setNotification(notificationType: NotificationType): void {
    let mappedType = notificationType

    if (
      [CampaignType.CARD_FRONT, CampaignType.CARD_BACK].includes(
        notificationType
      )
    ) {
      mappedType = NotificationType.CARD
    }

    const option = this.selectedMessage?.options.find(
      (o) => o.type === mappedType
    )

    if (!option || !option.notifications[0].id) {
      this.selectedNotification = undefined
      return
    }

    this.selectedNotification = {
      type: notificationType,
      notificationId: option.notifications[0].id
    }
  }

  private async fetchStats(): Promise<NotificationReportDTO | undefined> {
    if (this.selectedNotification?.notificationId) {
      try {
        const response = await fetchNotificationStats(
          this.appID,
          this.selectedNotification.notificationId
        )

        this.reports.set(this.selectedNotification.notificationId, response)
        return response
      } catch (error) {
        showNotification(
          'Something went wrong, please try again later.',
          ToastType.ERROR
        )
        console.error(error)
      }
    }

    return undefined
  }

  private createReport(
    report: NotificationReportDTO | undefined,
    notificationType: NotificationType
  ): void {
    switch (report?.type) {
      case NotificationType.EMAIL:
        this.currentReport = new EmailReport(report)
        break
      case NotificationType.ALERT:
        this.currentReport = new PushReport(report, true)
        break
      case NotificationType.PUSH:
        this.currentReport = new PushReport(report)
        break
      case NotificationType.IN_APP:
        this.currentReport = new InAppReport(report)
        break
      case NotificationType.CARD:
        this.currentReport = new CardReport(report, notificationType)
        break
      default:
        this.currentReport = undefined
        break
    }
  }

  public get numbers(): ISummary[] {
    if (!this.currentReport) return Array<ISummary>(4).fill({ label: '- -' })

    return this.currentReport.numbers
  }

  public get charts(): IChartData[] {
    if (!this.currentReport)
      return [
        {
          name: 'Sent',
          data: [],
          dataType: ChartDataType.NUMERIC
        },
        {
          name: 'Delivered',
          data: [],
          dataType: ChartDataType.NUMERIC
        }
      ]

    return this.currentReport.charts
  }

  public get goals(): IGoalPerformance[] {
    if (!this.currentReport) {
      return []
    }

    return this.currentReport.goals
  }

  public get clickPerformance(): ILinkReport[] | undefined {
    if (!this.currentReport || !(this.currentReport instanceof EmailReport))
      return undefined

    return this.currentReport.clickPerformance
  }
}

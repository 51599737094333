import { getChildByType } from '~/pages/Journeys/Journey.service'
import { JourneyBlockType } from '~/pages/Journeys/JourneyBuilder/Store/JourneyBuilder.interface'
import Review from '../emailBuilder/Review'
import StepStore from '../emailBuilder/StepStore'

export default class AlertCampaign {
  static createStepStores(source: any): {
    store: StepStore
    name: string
    displayName: string
    position: number
  }[] {
    const messageBlock = getChildByType(JourneyBlockType.MESSAGE, source.entry)
    if (!messageBlock) {
      return [
        {
          name: 'setUp',
          displayName: 'Set Up',
          store: source.entry,
          position: 1
        }
      ]
    }

    return [
      {
        name: 'setUp',
        displayName: 'Set Up',
        store: source.entry,
        position: 1
      },
      {
        name: 'alert',
        displayName: 'Push',
        store: messageBlock,
        position: 2
      },
      {
        name: 'targeting',
        displayName: 'Target',
        store: source.entry,
        position: 3
      },
      {
        name: 'deliver',
        displayName: 'Deliver',
        store: source.entry,
        position: 4
      },
      {
        name: 'review',
        displayName: 'Review',
        store: new Review(),
        position: 5
      }
    ]
  }
}

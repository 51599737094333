import { makeAutoObservable } from 'mobx'
import { ID } from '~/common.interface'
import { IAppDetails } from '~/dataStore/App.interface'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import Steps from '~/dataStore/Campaign/Steps'
import {
  createBlock as createNewBlock,
  fetchJourney
} from '~/pages/Journeys/Connector/Journeys.connector'
import {
  getBlock,
  getBlocksByLevel,
  getEdge,
  getLeafs,
  getRedirectEdge
} from '~/pages/Journeys/Journey.service'
import { JourneyErrors } from '~/pages/Journeys/Journeys.interface'
import { showGeneralError } from '~/utils/validations'
import {
  AddToSegment,
  Delay,
  DumbBlock,
  Message,
  Redirect,
  Split,
  Start
} from '../Blocks'
import Block from '../Blocks/Block.model'
import {
  EdgeWithData,
  IBlock,
  IJourneyTemplateBlock,
  JourneyBlockType,
  JourneyDTO,
  JourneyTemplate,
  NodeWithData
} from './JourneyBuilder.interface'

export default class JourneyStore {
  static blockFactory(
    blockType: JourneyBlockType,
    parent: IBlock,
    currentApp: Pick<IAppDetails, 'id' | 'image' | 'name'>,
    getJourneyID: () => ID
  ): Block {
    switch (blockType) {
      case JourneyBlockType.START:
        throw new Error('Two start blocks are not allowed')
      case JourneyBlockType.MESSAGE:
        return new Message(parent, currentApp)
      case JourneyBlockType.ADDTOSEGMENT:
        return new AddToSegment(parent)
      case JourneyBlockType.DELAY:
        return new Delay(parent)
      case JourneyBlockType.SPLIT:
        return new Split(parent, currentApp, getJourneyID)
      case JourneyBlockType.REDIRECT:
        return new Redirect(parent)
      default:
        return new DumbBlock(parent, blockType)
    }
  }

  entry: Start

  isValid = true

  builderType: 'journey' | 'campaign' = 'journey'

  steps: Steps | undefined = undefined

  constructor(
    public currentApp: Pick<IAppDetails, 'id' | 'image' | 'name'>,
    private readonly: boolean
  ) {
    makeAutoObservable(this, undefined, { autoBind: true })
    this.entry = new Start(this.currentApp.id)
    this.entry.builderType = this.builderType

    this.steps = Steps.initStores([CampaignType.ALERT], this)
  }

  public getJourneyId(): ID {
    if (!this.entry.blockID) {
      throw new Error('Journey is not saved yet')
    }
    return this.entry.blockID
  }

  public get journeyIsInitialized(): boolean {
    return getBlocksByLevel(this.entry)
      .flat()
      .every((block) => block.ready)
  }

  public get nodes(): NodeWithData[] {
    this.addColumnToLeafs()

    const elements: NodeWithData[] = []

    const levels = getBlocksByLevel(this.entry)

    levels.reverse().forEach((level) => {
      if (level) {
        level.forEach((block) => {
          elements.push(block.getNode())
        })
      }
    })

    return elements.reverse()
  }

  public get edges(): EdgeWithData[] {
    const edges: EdgeWithData[] = []

    const levels = getBlocksByLevel(this.entry)

    levels.forEach((level) => {
      level.forEach((block) => {
        if (block.parent) {
          const edge = getEdge(block, this.readonly)
          if (edge) {
            edges.push({
              ...edge,
              data: {
                ...edge.data,
                createBlock: (blockType) => this.createBlock(block, blockType)
              }
            })
          }
          if (block.blockType === JourneyBlockType.REDIRECT) {
            edges.push(
              getRedirectEdge(
                block,
                getBlock(block.redirectTo.value, this.entry)
              )
            )
          }
        }
      })
    })

    return edges
  }

  public async initJourney(journey: JourneyDTO): Promise<void> {
    this.entry = new Start(this.currentApp.id)
    this.entry.builderType = this.builderType
    journey.childBlocks.forEach((child) => this.initBlock(child, this.entry.id))

    const promises = []
    if (journey.segments?.length) {
      promises.push(
        this.entry.targeting.segments.fetchSegments(this.currentApp.id)
      )
    }
    if (journey.beacons?.length) {
      promises.push(
        this.entry.targeting.beacons.fetchBeacons(this.currentApp.id)
      )
    }
    if (journey.geofences?.length) {
      promises.push(
        this.entry.targeting.geofences.fetchGeofences(this.currentApp.id)
      )
    }

    this.steps = Steps.initStores([CampaignType.ALERT], this)

    return Promise.all(promises).then(() => {
      this.entry.fillBlock(journey)
    })
  }

  public initTemplate(template: JourneyTemplate): void {
    this.entry = new Start(this.currentApp.id, template.id)
    this.entry.builderType = this.builderType
    template.diagram.forEach((child) => this.initBlock(child, this.entry.id))

    this.entry.fillBlock({
      name: '',
      templateId: template.id,
      childBlocks: template.diagram
    })

    this.steps = Steps.initStores([CampaignType.ALERT], this)
  }

  private initBlock(
    block: IJourneyTemplateBlock | undefined,
    parentBlockId: string
  ) {
    if (block) {
      const newBlock = this.addNewLeaf(
        parentBlockId,
        block.type || block.blockType
      )

      if (newBlock) {
        // TODO: refactor for custom journeys!
        block.childBlocks?.forEach((child) => {
          this.initBlock(child, newBlock.id)
        })
      }
    }
  }

  public addNewLeaf(
    parentBlockId: string,
    blockType: JourneyBlockType
  ): IBlock | undefined {
    const parent = getBlock(parentBlockId, this.entry)

    if (!parent) {
      return undefined
    }

    const leaf = JourneyStore.blockFactory(
      blockType,
      parent,
      this.currentApp,
      this.getJourneyId
    )
    parent.children.push(leaf)

    return leaf
  }

  public async removeBlock(block: IBlock): Promise<void> {
    if (!block.parent) {
      return
    }

    try {
      await block.remove(this.currentApp.id, this.getJourneyId())
      block.removeFromTree()
    } catch (error) {
      console.error(error)
      showGeneralError(error.body.error)
    }
  }

  public async createBlock(
    currentBlock: IBlock & { blockID: ID },
    blockType: JourneyBlockType
  ): Promise<void> {
    // TODO: check if all is needed
    if (
      !currentBlock.parent?.blockID ||
      !this.getJourneyId() ||
      (blockType === JourneyBlockType.EXIT &&
        !currentBlock.children[0]?.blockID)
    ) {
      throw new Error('Block is not connected to any diagram yet')
    }

    try {
      const response = await createNewBlock(
        this.currentApp.id,
        this.getJourneyId(),
        {
          parentId: currentBlock.parent.blockID,
          childBlockId: currentBlock.blockID,
          showChildBlocks: true,
          type: blockType,
          ...(blockType === JourneyBlockType.SPLIT && {
            options: [
              {
                childBlockId: currentBlock.blockID,
                type: 'yes'
              }
            ]
          })
        }
      )
      const createdBlock = JourneyStore.blockFactory(
        blockType,
        currentBlock.parent,
        this.currentApp,
        this.getJourneyId
      )
      createdBlock.fillBlock({ id: response.id })
      currentBlock.replaceInParentWith(createdBlock)
      response.childBlocks.forEach((child: IBlock) =>
        this.initBlock(child, createdBlock.blockID)
      )
      createdBlock.fillBlock(response)
    } catch (error) {
      console.error(error)
    }
  }

  public async save(): Promise<ID> {
    return this.entry.saveJourney(this.currentApp.id)
  }

  public validate(block: Block): boolean {
    if (!block.validateBlock()) {
      this.isValid = false
    }
    block.children.forEach((child) => this.validate(child))

    return this.isValid
  }

  public resetError(): void {
    this.isValid = true
  }

  public setServerErrors(errors: JourneyErrors): void {
    this.isValid = false
    this.entry.setServerErrors(errors)
  }

  public get errors(): string[] {
    const levels = getBlocksByLevel(this.entry).flat()

    return levels.reduce((arr: string[], block) => {
      return arr.concat(block.launchErrors)
    }, [])
  }

  public async fetch(journeyId: ID): Promise<void> {
    const response = await fetchJourney(this.currentApp.id, journeyId)
    return this.initJourney(response)
  }

  /**
   * Adds horizontal position index to each block to calculate position of the node on canvas
   */
  private addColumnToLeafs(): void {
    const arr: IBlock[] = []
    getLeafs(this.entry, arr)
    const halfLength = arr.length / 2
    arr.forEach((block, index) => block.node.setColumn(index - halfLength))
  }
}

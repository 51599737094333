import { useForm, Controller } from 'react-hook-form'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row
} from 'reactstrap'
import { useParams } from 'react-router-dom'
import cn from 'classnames'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { ERRORS_DICT, FormValues } from './BannoSettingsSection.interface'
import Input from '~/components/forms/Input'
import { updateBannoSettings, upsertBannoPem } from '~/api/appSettings'
import { getFormServerSideErrors, showGeneralError } from '~/utils/validations'
import { showNotification, NotificationType } from '~/utils/Notification'
import { useStore, withStore } from '~/dataStore'
import PasswordInput from '~/components/forms/PasswordInput'

const BannoSettingsSection = (): React.ReactElement => {
  const { appId } = useParams<{ appId: string }>()
  const {
    app: { appDetails }
  } = useStore()
  const [publicKey, setPublicKey] = useState<string>(
    appDetails.bannoConfiguration.publicKey
  )
  const [isUpdatingPem, setIsUpdatingPem] = useState(false)

  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors, isSubmitting }
  } = useForm<FormValues>({
    values: {
      clientId: appDetails?.bannoConfiguration.clientId,
      clientSecret: appDetails?.bannoConfiguration.clientSecret,
      institutionId: appDetails?.bannoConfiguration.institutionId
    }
  })

  const updateConfig = async (data: FormValues) => {
    try {
      await updateBannoSettings(appId, data)
      showNotification('Settings successfully saved', NotificationType.SUCCESS)
    } catch (error: Error & { body: any }) {
      if (error.body?.errors) {
        getFormServerSideErrors(error.body.errors, ERRORS_DICT).forEach((er) =>
          setError(er.name, { type: 'server', message: er.message })
        )
      } else {
        showGeneralError()
      }
    }
  }

  const generatePublicKey = async () => {
    try {
      setIsUpdatingPem(true)
      const response = await upsertBannoPem(appId)
      setPublicKey(response.publicKey)
      showNotification(
        'Public key successfully generated',
        NotificationType.SUCCESS
      )
    } catch (error: Error & { body: any }) {
      showGeneralError()
    } finally {
      setIsUpdatingPem(false)
    }
  }

  useEffect(() => {
    setPublicKey(appDetails.bannoConfiguration.publicKey)
  }, [appDetails.bannoConfiguration.publicKey])

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h3" className="mb-0 mt-2">
          Banno
        </CardTitle>
      </CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit(updateConfig)} className="py-3">
          <Row>
            <Col xs={8} className="pe-5">
              <h5>Settings</h5>
              <div className="d-flex mt-4" />
              <Input
                label="Client ID"
                name="clientId"
                className={cn('mb-3', {
                  'form-group--error': errors.clientId
                })}
                validation={{
                  required: {
                    value: true,
                    message: 'Client ID is missing'
                  }
                }}
                errorTooltip={errors?.clientId?.message}
                register={register}
                placeholder="Client ID"
              />
              <Input
                label="Institution ID"
                name="institutionId"
                validation={{
                  required: {
                    value: true,
                    message: 'Institution ID is missing'
                  }
                }}
                className={cn('mb-3', {
                  'form-group--error': errors.institutionId
                })}
                errorTooltip={errors?.institutionId?.message}
                register={register}
                placeholder="Institution ID"
              />

              <Controller
                control={control}
                name="clientSecret"
                rules={{
                  required: {
                    value: true,
                    message: 'Client Secret is missing'
                  }
                }}
                render={({ field: { value, onChange } }) => (
                  <PasswordInput
                    value={value}
                    name="clientSecret"
                    onChange={onChange}
                    placeholder="Client Secret"
                    error={errors.clientSecret?.message}
                    label="Client Secret"
                  />
                )}
              />

              <Button
                color="primary"
                disabled={isSubmitting}
                type="submit"
                className="mt-4 d-block">
                Save Changes
                {isSubmitting && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    size="xs"
                    className="ms-2"
                  />
                )}
              </Button>
            </Col>
            <Col xs={4} className="d-flex justify-content-between flex-column">
              <h5>Public Key</h5>
              <div>
                {publicKey ? (
                  <pre className="muted mx-auto text-11 text-break">
                    {publicKey}
                  </pre>
                ) : (
                  <p className="muted mx-auto text-center">
                    Generate public key.
                  </p>
                )}
              </div>
              <Button
                color="primary"
                onClick={generatePublicKey}
                className="mt-4 d-block">
                {publicKey ? 'Regenerate' : 'Generate'}
                {isUpdatingPem && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    size="xs"
                    className="ms-2"
                  />
                )}
              </Button>
            </Col>
          </Row>
        </form>
      </CardBody>
    </Card>
  )
}

export default withStore(BannoSettingsSection)

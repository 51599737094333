import {
  CampaignType,
  ICampaign,
  IPreview,
  IPreviewParts
} from '~/dataStore/Campaign/Campaign.interface'

export enum ChartDataType {
  NUMERIC,
  PERCENTAGE
}

export enum DataChartType {
  LINE = 'line',
  COLUMN = 'column'
}

export type ChartType =
  | 'line'
  | 'area'
  | 'bar'
  | 'histogram'
  | 'pie'
  | 'donut'
  | 'radialBar'
  | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'candlestick'
  | 'boxPlot'
  | 'radar'
  | 'polarArea'
  | 'rangeBar'
  | 'treemap'

export interface IReportModel {
  report?: IReport
  summary: ISummary[]
  summaryNumbers: ISummary[]
  numbers: ISummary[]
  charts: IChartData[]
  goalsPerformance: {
    primary: IGoalsPerformanceRow[]
    secondary: IGoalsPerformanceRow[]
  }
  clickPerformance?: ILinkReport[]
  preview?: IPreviewParts
}

export interface IConversionGoalReport {
  name: string
  conversionRate: string
  conversionDiff: string
  revenueRate: string
  revenueDiff: string
  controlGroupConversionRate: string
}

export type IGoalsPerformanceRow = Partial<
  Omit<IConversionGoalReport, 'controlGroupConversionRate'>
>

export interface IChartReport {
  total: number
  totalGraph: IGraphData[]
  uniqGraph?: IGraphData[]
  rateGraph?: IGraphData[]
  uniq?: number
  rate?: number
}

export interface IChartData {
  name: string
  data: IGraphData[]
  valueCurrent?: number | string
  dataType: ChartDataType
  dataChartType?: DataChartType
}

export interface ISeriesData extends IChartData {
  color?: { start: string; end: string; legend: string }
  active: boolean
  value?: number | string
}

export interface IRadialBarChartData {
  title: string
  label: string
  value: number | undefined
  dataType?: ChartDataType
}

export interface IGoalConversion extends IRadialBarChartData {
  tooltip: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISMSReport {}

export interface IGraphData {
  x: string | number
  y: string | number
}

export interface ILinkReport {
  target: string
  label: string
  totalClicks: number
  uniqueClicks: number
  totalClicksRate: string
  uniqueClicksRate: string
}

export interface INumberReport {
  total: number
  rate?: number
}

export interface ISummary {
  label: string
  content?: string | number | { value: string; tooltip: string }[]
  icon?: string
  tooltip?: string
  className?: string
  rateLabel?: string
  rate?: number | string
  rateTooltip?: string
  disabled?: boolean
}

export interface ISummaryRate extends ISummary {
  chartData?: IGraphData[]
}

export interface IReport extends ICampaign {
  primaryGoal: IConversionGoalReport
  secondaryGoal: IConversionGoalReport
  stats: IStats | undefined
  preview: IPreview
  controlGroupParsed: string | null
}

export interface IStats {
  send: IChartReport
  email: IEmailReport
  push: IPushReport
  inApp: IInAppReport
  card: ICardReport
  sms: ISMSReport
  type: CampaignType[]
  exportUrl?: string
  sdk?: { old: number; new: number }
}

export interface IEmailReport {
  delivery: IChartReport
  unsubscribe: INumberReport
  bounce: INumberReport
  open: IChartReport
  links: ILinkReport[]
  linkClick: IChartReport
}

export interface IPushReport {
  delivery: IChartReport
  pendingDelivery?: IChartReport
  bounce?: IChartReport
  buttonClickOne?: IChartReport
  buttonClickTwo?: IChartReport
  buttonClickThree?: IChartReport
  buttonClickReply?: IChartReport
  dismiss: IChartReport
  impression?: IChartReport
  click?: IChartReport
  pushClick: IChartReport
}
export interface IInAppReport {
  delivery: IChartReport
  pendingDelivery?: IChartReport
  bounce?: IChartReport
  dismiss: IChartReport
  impression: IChartReport
  buttonClickOne?: IChartReport
  buttonClickTwo?: IChartReport
  buttonClickThree?: IChartReport
  buttonClick?: IChartReport
  timeOut?: IChartReport
}
export interface ICardReport {
  delivery: IChartReport
  pendingDelivery?: IChartReport
  delete?: IChartReport
  front: {
    frontButtonClickOne: IChartReport
    frontButtonClickReply?: IChartReport
    frontImpression: IChartReport
    frontButtonClick?: IChartReport
  }
  back?: {
    backButtonClickOne?: IChartReport
    backButtonClickTwo?: IChartReport
    backButtonClickReply?: IChartReport
    backImpression?: IChartReport
    backButtonClick?: IChartReport
  }
}

import {
  faPaperPlane,
  faSpinner,
  faUsers
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment-timezone'
import { useState } from 'react'
import { Button, ModalBody, ModalFooter } from 'reactstrap'

import defaultAppLogo from '~/assets/img/default-app-logo.svg'
import { useStore, withStore } from '~/dataStore'
import { DATE_FORMATS } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import { NotificationType, showNotification } from '~/utils/Notification'

import Targeting from '~/dataStore/emailBuilder/Targeting/Targeting'
import TargetingSummary from '~/pages/Campaign/CampaignReview/TargetingSummary'
import './SendCampaignModal.scss'

const SendCampaignModal = ({
  onSubmit,
  showErrors,
  onClose,
  targeting,
  startAt,
  startNow
}: {
  onSubmit: () => Promise<void>
  onClose: () => void
  showErrors: (error: Error & { body: any }) => void
  startAt: Date
  startNow: boolean | string
  targeting: Targeting
}) => {
  const {
    app: {
      appDetails: { name, image }
    }
  } = useStore()
  const [isSending, setIsSending] = useState(false)

  const sendCampaign = async () => {
    try {
      setIsSending(true)
      await onSubmit()
    } catch (error) {
      if (error.body?.errors || error.body?.details) {
        console.error(error)
        showErrors(error)
      } else {
        showNotification(
          'Sending campaign failed, please try again later.',
          NotificationType.ERROR
        )
      }
    } finally {
      setIsSending(false)
    }
  }

  return (
    <>
      <ModalBody className="py-5 bg-water-gray">
        <div className="d-flex justify-content-center">
          <div className="send-campaign-modal__box">
            <div className="d-flex align-items-center my-4">
              <img
                src={image || defaultAppLogo}
                alt="Application"
                className="send-campaign-modal__icon send-campaign-modal__icon--rounded"
              />
              <p className="send-campaign-modal__value send-campaign-modal__value--app ps-3">
                {name}
              </p>
            </div>
            <div className="d-flex align-items-center my-4">
              <div className="send-campaign-modal__icon">
                <FontAwesomeIcon
                  className="text-muted"
                  size="2x"
                  icon={faUsers}
                />
              </div>
              <div className="ps-3">
                <TargetingSummary
                  targeting={targeting}
                  withUsers
                  className="campaign-header__target--small"
                />
              </div>
            </div>

            <div className="d-flex align-items-center my-4">
              <div className="send-campaign-modal__icon">
                <FontAwesomeIcon
                  size="2x"
                  className="text-muted"
                  icon={faPaperPlane}
                />
              </div>
              <div className="ps-3">
                <p className="send-campaign-modal__value">
                  {startNow
                    ? 'Sending Now'
                    : moment(startAt).format(
                        `${DATE_FORMATS.HOUR_MINUTE_MERIDIAN}, ${DATE_FORMATS.MONTH_DAY}`
                      )}
                </p>
                <p className="send-campaign-modal__value-description">
                  {startNow
                    ? 'This Campaign will be sent now'
                    : 'Scheduled time and date for this campaign'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="" className="btn--hover" onClick={onClose}>
          Back to Review
        </Button>
        <Button
          color="primary"
          disabled={isSending}
          className="btn--hover"
          onClick={sendCampaign}>
          {isSending && (
            <FontAwesomeIcon className="me-1" icon={faSpinner} spin size="xs" />
          )}
          <span>Send Campaign</span>{' '}
          <FontAwesomeIcon icon={faPaperPlane} className="ms-1" />
        </Button>
      </ModalFooter>
    </>
  )
}

export default withStore(SendCampaignModal)

export const ERRORS_DICT = {
  clientId: {
    'Must be a String': 'Client ID is missing'
  },
  clientSecret: {
    'Must be a String': 'Client Secret is missing'
  },
  institutionId: {
    'Must be a String': 'Institution ID is missing'
  }
}

export interface FormValues {
  clientId: string
  clientSecret: string
  institutionId: string
}

import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useParams } from 'react-router-dom'
import { useStore, withStore } from '~/dataStore'
import JourneyStore from '../Journeys/JourneyBuilder/Store/JourneyStore'

const NotificationBuilderContext = createContext<undefined | JourneyStore>(
  undefined
)

function NotificationBuilderProvider({
  children
}: PropsWithChildren<any>): React.ReactElement | null {
  const { app } = useStore()

  const { mode } = useParams<{ mode: 'builder' | 'details' }>()

  const firstRun = useRef(true)
  const [store, setStore] = useState(() => {
    const js = new JourneyStore(app.appDetails, mode === 'details')
    js.builderType = 'campaign'
    return js
  })

  useEffect(() => {
    app.closeCurtain()
    if (firstRun.current) {
      firstRun.current = false
      store.builderType = 'campaign'
      return
    }
    const s = new JourneyStore(app.appDetails, mode === 'details')
    s.builderType = 'campaign'
    setStore(s)
    app.openCurtain()
  }, [app.appDetails.id])

  if (!store.currentApp.id) {
    return null
  }

  return (
    <NotificationBuilderContext.Provider value={store}>
      {children}
    </NotificationBuilderContext.Provider>
  )
}

export const NotificationBuilderProviderWithStore = withStore(
  NotificationBuilderProvider
)

const useJourneyStore = (): JourneyStore => {
  const context = useContext(NotificationBuilderContext)
  if (!context) {
    throw new Error('There is no Provider for this context')
  }

  return context
}

export default useJourneyStore

import React, { PropsWithChildren } from 'react'
import Skeleton from 'react-loading-skeleton'
import cn from 'classnames'
import { Card, CardBody, CardText, CardTitle } from 'reactstrap'
import camelCase from 'lodash/camelCase'
import { isEmpty } from '~/utils/utilities'
import ConditionalWrapper from '~/components/ConditionalWrapper'

import Tooltip from './Tooltip'

interface IProps {
  title?: string | React.ReactElement
  titleTag?: React.ElementType
  text?: number | string | React.ReactElement | null
  classes?: string
  tooltip?: string
  disabled?: boolean
  cardClasses?: string
  cardBodyClasses?: string
  icon?: React.ReactElement
}

export default function StatCard({
  title,
  titleTag,
  text,
  children,
  classes,
  tooltip,
  disabled,
  cardClasses,
  cardBodyClasses,
  icon
}: PropsWithChildren<IProps>): React.ReactElement {
  return (
    <Card
      className={cn(
        'reports-box-wrapper',
        { 'reports-box-wrapper--disabled o-50 pointer-none': disabled },
        cardClasses
      )}
      data-testid={`${camelCase(title)}StatsCard`}>
      <CardBody className={cn('reports-box', cardBodyClasses)}>
        {tooltip && (
          <div className="d-flex justify-content-end reports__tooltip">
            <Tooltip tooltip={tooltip} />
          </div>
        )}
        <div className={`reports${classes ? ` ${classes}` : ''}`}>
          {isEmpty(text) && !children && !disabled ? (
            <div className="w-50">
              <Skeleton />
              <Skeleton height="30px" />
            </div>
          ) : (
            <>
              <ConditionalWrapper
                condition={!!icon}
                wrapper={(child: React.ReactElement) => (
                  <div className="d-flex items-center text-start">{child}</div>
                )}>
                <>
                  {icon && <div className="me-4">{icon}</div>}
                  <div>
                    {title && (
                      <CardTitle
                        className="reports__title"
                        tag={titleTag || 'h2'}>
                        {title}
                      </CardTitle>
                    )}
                    {!isEmpty(text) && (
                      <CardText className="reports__text">{text}</CardText>
                    )}
                  </div>
                </>
              </ConditionalWrapper>
              {children}
            </>
          )}
        </div>
      </CardBody>
    </Card>
  )
}

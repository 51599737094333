export enum TARGETS {
  SEGMENTS = 'segments',
  SUBSCRIPTIONS = 'targetSubscriptions',
  GEOFENCES = 'geofences',
  BEACONS = 'beacons',
  IN_APP_EVENTS = 'in_app_events',
  EVENTS = 'events'
}

export const ALPHABET = 'abcdefghij'

export const DELIVER_PANELS = {
  START_NOW: 'startNow',
  LIMITS: 'limits'
}

export const DELIVERY = {
  CURRENT: 'current',
  CURRENT_FUTURE: 'current_future'
} as const

export const GOALS_TIME_UNITS = ['hours', 'days']

export const DEFAULT_EXPIRY_TIME_FRAME = 'days'

export const DEFAULT_EXPIRY_TIME_VALUE = 2

export const DEFAULT_DELIVER_TIME_FRAME = 'week'

export const MIN_DWELL_TIME = 1
export const MAX_DWELL_TIME = 60

export const CAMPAIGN_TYPES = {
  CARD_INBOX: 'CardInboxCampaign',
  CARD_IN_APP: 'CardInAppNotificationCampaign',
  CARD_PUSH_NOTIFICATIONS: 'CardPushNotificationCampaign',
  CARD_SMS_NOTIFICATION: 'CardSmsNotificationCampaign',
  PUSH_NOTIFICATION: 'PushNotificationCampaign',
  ALERT_NOTIFICATION: 'AlertNotificationCampaign',
  IN_APP_NOTIFICATION: 'InAppNotificationCampaign',
  CARD_EMAIL_NOTIFICATION: 'CardEmailNotificationCampaign',
  EMAIL_NOTIFICATION_CAMPAIGN: 'EmailNotificationCampaign',
  SMS_NOTIFICATION_CAMPAIGN: 'SmsNotificationCampaign'
}

export const CAMPAIGN_TYPES_LIST = [
  CAMPAIGN_TYPES.CARD_INBOX,
  CAMPAIGN_TYPES.CARD_IN_APP,
  CAMPAIGN_TYPES.CARD_PUSH_NOTIFICATIONS,
  CAMPAIGN_TYPES.CARD_SMS_NOTIFICATION,
  CAMPAIGN_TYPES.IN_APP_NOTIFICATION,
  CAMPAIGN_TYPES.PUSH_NOTIFICATION,
  CAMPAIGN_TYPES.ALERT_NOTIFICATION
]

export const DAYS_OPTIONS = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
  ALL_DAYS: 'All days',
  MONDAY_FRIDAY: 'Monday to Friday'
}

export const ALL_DAYS_OPTIONS_LIST = [
  DAYS_OPTIONS.ALL_DAYS,
  DAYS_OPTIONS.MONDAY_FRIDAY,
  DAYS_OPTIONS.MONDAY,
  DAYS_OPTIONS.TUESDAY,
  DAYS_OPTIONS.WEDNESDAY,
  DAYS_OPTIONS.THURSDAY,
  DAYS_OPTIONS.FRIDAY,
  DAYS_OPTIONS.SATURDAY,
  DAYS_OPTIONS.SUNDAY
]

export const DAYS_LIST = [
  DAYS_OPTIONS.MONDAY,
  DAYS_OPTIONS.TUESDAY,
  DAYS_OPTIONS.WEDNESDAY,
  DAYS_OPTIONS.THURSDAY,
  DAYS_OPTIONS.FRIDAY,
  DAYS_OPTIONS.SATURDAY,
  DAYS_OPTIONS.SUNDAY
]

export const WEEK_DAYS_LIST = [
  DAYS_OPTIONS.MONDAY,
  DAYS_OPTIONS.TUESDAY,
  DAYS_OPTIONS.WEDNESDAY,
  DAYS_OPTIONS.THURSDAY,
  DAYS_OPTIONS.FRIDAY
]

export const BLANK_TEMPLATE_ID = '-1'

import cn from 'classnames'
import {
  CampaignType,
  DeviceType,
  IPreviewParts
} from '~/dataStore/Campaign/Campaign.interface'
import CardBackPreview from '../Card/CardBackPreview'
import CardFrontPreview from '../Card/CardFrontPreview'
import PreviewCard from './PreviewCard'
import PreviewParts from '../Parts/PreviewParts'
import PushPreview from '../Push/PushPreview'
import SMSPreview from '../SMS/SMSPreview'
import { useStore, withStore } from '~/dataStore'

interface IProps {
  campaignType: CampaignType
  preview?: IPreviewParts
  deviceType: DeviceType
  noGap?: boolean
  withTooltip?: boolean
}

function PreviewBox({
  campaignType,
  preview,
  deviceType,
  noGap,
  withTooltip
}: IProps): React.ReactElement | null {
  const {
    app: {
      appDetails: { featureFlags }
    }
  } = useStore()
  function getCardSide(type: CampaignType): 'front' | 'back' | undefined {
    if ([CampaignType.CARD_FRONT, CampaignType.CARD_BACK].includes(type)) {
      return campaignType === CampaignType.CARD_FRONT ? 'front' : 'back'
    }

    return undefined
  }

  if (!preview || !('parts' in preview)) {
    return null
  }

  const parts = preview.parts.slice()

  return (
    <div
      className={cn('preview', {
        'preview--no-gap': noGap,
        'preview--q2': featureFlags.q2InApp,
        'preview--alert': featureFlags.bannoPushSendToUrl && !featureFlags.push
      })}>
      {campaignType === CampaignType.PUSH && (
        <PushPreview deviceType={deviceType} parts={preview.parts} />
      )}

      {campaignType === CampaignType.SMS && (
        <SMSPreview deviceType={deviceType} parts={preview.parts} />
      )}

      {campaignType === CampaignType.CARD_FRONT && (
        <CardFrontPreview
          deviceType={deviceType}
          parts={preview.parts}
          withTooltip={withTooltip}
        />
      )}

      {campaignType === CampaignType.CARD_BACK && (
        <CardBackPreview
          deviceType={deviceType}
          parts={preview.parts}
          reply={preview.reply}
        />
      )}

      {campaignType === CampaignType.IN_APP && (
        <PreviewCard
          key={campaignType}
          side={getCardSide(campaignType)}
          emptyPreview={!parts.length}
          deviceType={deviceType}
          position={'position' in preview ? preview.position : undefined}
          size={'size' in preview ? preview.size : undefined}>
          <PreviewParts parts={parts} />
        </PreviewCard>
      )}
    </div>
  )
}

export default withStore(PreviewBox)

import { useState } from 'react'
import DownloadBtn from '~/components/DownloadBtn'
import RangeDatePickerDropdown from '~/components/forms/DatePicker/RangeDatePickerDropdown'
import ToggleRadioSwitch from '~/components/forms/ToggleRadioSwitch'
import { withStore } from '~/dataStore'
import useJourneyReportStore from './JourneyReport.context'
import JourneyOverviewReport from './UI/JourneyOverviewReport/JourneyOverviewReport'
import NotificationReport from '../../Campaign/Notification/Report/UI/NotificationReport'
import MessageDropdown from './UI/MessageDropdown'

function JourneyReport(): React.ReactElement {
  const store = useJourneyReportStore()
  const [view, setView] = useState<'overview' | 'details'>('overview')

  return (
    <>
      <div className="mb-4 d-flex align-items-center justify-content-between">
        <div>
          <ToggleRadioSwitch
            options={[
              {
                name: 'overview',
                value: 'overview',
                label: 'Overview',
                width: 150
              },
              {
                name: 'overview',
                value: 'details',
                label: 'Details',
                width: 150
              }
            ]}
            className="ms-auto"
            onChange={(e) => {
              setView(e.target.value as 'overview' | 'details')
            }}
            selected={view}
          />
        </div>
        <div className="o-50">
          {/* //TODO set start and end date from journey, handle range date change when we add support for date filtering */}
          <RangeDatePickerDropdown
            startDateFilter={store.startAtDay}
            endDateFilter={new Date()}
            disabled
            setFilter={() => {
              //
            }}
          />
          <DownloadBtn classes="ms-3 btn--wide" url="" disabled>
            Export Report
          </DownloadBtn>
        </div>
      </div>

      {view === 'overview' && <JourneyOverviewReport journey={store} />}

      {view === 'details' && store.report && (
        <>
          <MessageDropdown
            messages={store.report.messageBlocks}
            onMessageSelect={store.notificationReport.selectMessage}
            onNotificationSelect={store.notificationReport.selectNotification}
            selectedMessage={store.notificationReport.selectedMessage}
            selectedNotificationType={
              store.notificationReport.selectedNotification?.type
            }
          />
          <NotificationReport
            notificationType={
              store.notificationReport.selectedNotification?.type
            }
            numbers={store.notificationReport.numbers}
            charts={store.notificationReport.charts}
            goals={store.notificationReport.goals}
            clickPerformance={store.notificationReport.clickPerformance}
          />
        </>
      )}
    </>
  )
}

export default withStore(JourneyReport)

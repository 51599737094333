import React from 'react'
import { Row, Col, Collapse } from 'reactstrap'
import cn from 'classnames'

import { withStore, useStore } from '~/dataStore'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'

import CampaignDeliveryLimits from './Panels/CampaignDeliveryLimits'
import CampaignStart from './Panels/CampaignStart'
import CampaignDeliverySelect from './CampaignDeliverySelect'

const DeliveryStep = () => {
  const {
    campaign: {
      targeting: { isSegmentsOnlyActiveTarget },
      deliver: {
        deliverUi: {
          isPanelsContainerActive,
          isStartPanelCollapsed,
          isDeliveryFutureOrDefault
        }
      }
    }
  } = useStore()

  const headerTitle = () =>
    isSegmentsOnlyActiveTarget
      ? 'Which users should receive this campaign?'
      : 'How often should users receive it?'

  return (
    <Row>
      <Col
        widths={['xs', 'xl', 'xxl']}
        xl={{ size: 10, offset: 1 }}
        xxl={{ size: 8, offset: 2 }}>
        <Header>
          <HeaderTitle>{headerTitle()}</HeaderTitle>
        </Header>
        <div className="border border-body bg-white rounded mb-4">
          {isSegmentsOnlyActiveTarget && <CampaignDeliverySelect />}

          <div className={cn({ 'my-5': isPanelsContainerActive })}>
            <Collapse isOpen={isDeliveryFutureOrDefault}>
              <div className="px-5">
                <CampaignDeliveryLimits />
              </div>
            </Collapse>

            <Collapse isOpen={isStartPanelCollapsed}>
              <div className="px-5">
                <CampaignStart />
              </div>
            </Collapse>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default withStore(DeliveryStep)

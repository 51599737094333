import { ReactElement, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UncontrolledTooltip } from 'reactstrap'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import cn from 'classnames'
import { uniqueId } from 'lodash'
import Input from '~/components/forms/Input'

interface IProps {
  error: string | undefined
  label?: string | React.ReactElement
  name: string
  className?: string
  onChange: (value: unknown) => void
  validation?: {
    required: { value: boolean; message: string }
    pattern?: { value: boolean; message: string }
    validate?: { value: boolean; message: string }
  }
  placeholder?: string
  value?: string
}

const PasswordInput = ({
  error,
  name,
  label,
  onChange,
  className,
  validation,
  placeholder,
  value
}: IProps): ReactElement => {
  const [passwordShown, setPasswordShown] = useState<boolean>(false)
  const uniqId = useRef(uniqueId('toggle-password-visibility-'))
  return (
    <Input
      value={value}
      name={name}
      onChange={onChange}
      label={label}
      labelClassName="w-100"
      placeholder={placeholder || 'Enter Password'}
      errorTooltip={error}
      invalid={!!error}
      className={cn(className, {
        'form-group--error': !!error
      })}
      validation={validation}
      suffix={
        <>
          <FontAwesomeIcon
            onClick={(e) => {
              e.preventDefault()
              setPasswordShown((prev) => !prev)
            }}
            id={uniqId.current}
            className="cursor-pointer"
            icon={passwordShown ? faEyeSlash : faEye}
            size="lg"
            tabIndex={-1}
          />
          <UncontrolledTooltip placement="top" target={uniqId.current}>
            {passwordShown ? 'Hide' : 'Show'} password
          </UncontrolledTooltip>
        </>
      }
      type={passwordShown ? 'text' : 'password'}
    />
  )
}

export default PasswordInput

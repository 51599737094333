import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'

export const MCampaignTypeToName = new Map<CampaignType, string>([
  [CampaignType.CARD, 'Feed Post'],
  [CampaignType.CARD_FRONT, 'Feed Post (Front)'],
  [CampaignType.CARD_BACK, 'Feed Post (Back)'],
  [CampaignType.EMAIL, 'Email'],
  [CampaignType.IN_APP, 'In App'],
  [CampaignType.PUSH, 'Push'],
  [CampaignType.ALERT, 'Push'],
  [CampaignType.SMS, 'Sms']
])

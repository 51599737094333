import { ReactElement } from 'react'
import { withStore } from '~/dataStore'
import AlertNotificationBuilder from '~/pages/Campaign/Notification/AlertNotification/UI/AlertNotificationBuilder'
import { getChildByType } from '~/pages/Journeys/Journey.service'
import { JourneyBlockType } from '~/pages/Journeys/JourneyBuilder/Store/JourneyBuilder.interface'
import useJourneyStore from '../NotificationBuilder.context'

function AlertStep(): ReactElement | null {
  const store = useJourneyStore()
  const messageBlock = getChildByType(JourneyBlockType.MESSAGE, store.entry)

  return (
    <AlertNotificationBuilder
      alertNotification={messageBlock?.options?.[0].notification}
      colsWidth={{
        left: { xxl: { size: 8 } },
        right: { xxl: { size: 4 } }
      }}
    />
  )
}

export default withStore(AlertStep)

import cn from 'classnames'
import { useState } from 'react'
import { Col, Collapse, Row } from 'reactstrap'
import { DELIVERY } from '~/pages/CampaignBuilder/Email/consts'

import usersIconFuture from '~/assets/img/users-future.svg'
import usersIcon from '~/assets/img/users.svg'
import Checkbox from '~/components/forms/Checkbox'
import DateAndTimeInput from '~/components/forms/DateAndTimeInput'
import TimeZonePicker from '~/components/forms/TimeZonePicker'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import { withStore } from '~/dataStore'
import OptionBox from '~/pages/CampaignBuilder/DeliveryStep/CampaignDeliverySelect/OptionBox'
import Section from '~/pages/CampaignBuilder/DeliveryStep/Panels/components/Section'
import useJourneyStore from '~/pages/NotificationBuilder/NotificationBuilder.context'

const DeliveryStep = () => {
  const {
    entry: {
      setStartAt,
      startAt,
      endAt,
      setEndAt,
      setEndActive,
      endAtActive,
      timeZoneName,
      setTimezone,
      delivery,
      setDelivery
    }
  } = useJourneyStore()

  const [isDeliverySelected, setDeliverySelected] = useState(!!delivery.value)

  return (
    <Row>
      <Col
        widths={['xs', 'xl', 'xxl']}
        xl={{ size: 10, offset: 1 }}
        xxl={{ size: 8, offset: 2 }}>
        <Header>
          <HeaderTitle>Which users should receive this campaign?</HeaderTitle>
        </Header>
        <div className="border border-body bg-white rounded mb-4">
          <div className="d-flex">
            <OptionBox
              iconSrc={usersIcon}
              title="One Time Segment"
              description="A One time Segment includes users who are in this segment now"
              borderRight
              active={delivery.value === DELIVERY.CURRENT}
              isDeliverySelected={isDeliverySelected}
              className="pt-5"
              wrapperClassName={cn({
                'o-50':
                  isDeliverySelected && delivery.value !== DELIVERY.CURRENT,
                'border-bottom':
                  delivery.value !== DELIVERY.CURRENT && isDeliverySelected
                // 'disabled-label': isSended
              })}
              onSelect={() => {
                setDelivery(DELIVERY.CURRENT)
                setDeliverySelected(true)
                // if (!isSended) {
                //   setDeliveryCurrent()
                //   validateDelivery()
                // }
              }}
            />
            <OptionBox
              iconSrc={usersIconFuture}
              title="Live Segment"
              isDeliverySelected={isDeliverySelected}
              description="A Live segment includes users who are in this segment now and users who enter this segment in the future"
              active={delivery.value === DELIVERY.CURRENT_FUTURE}
              // isDeliverySelected={isDeliverySelected}
              wrapperClassName={cn({
                'o-50':
                  isDeliverySelected &&
                  delivery.value !== DELIVERY.CURRENT_FUTURE,
                'border-bottom':
                  delivery.value !== DELIVERY.CURRENT_FUTURE &&
                  isDeliverySelected
                // 'disabled-label': isSended
              })}
              onSelect={() => {
                setDelivery(DELIVERY.CURRENT_FUTURE)
                setDeliverySelected(true)

                // if (!isSended) {
                //   setDeliveryCurrentFuture()
                //   validateDelivery()
                // }
              }}
            />
          </div>
          <Collapse isOpen={isDeliverySelected}>
            <div className="px-5 py-4">
              <div className="pt-4 ps-4 pe-4 pb-1 mt-3 bg-light">
                <Section>
                  <div className="d-inline-block">
                    <div className="d-flex align-items-center">
                      <DateAndTimeInput
                        value={startAt.value}
                        onChange={setStartAt}
                        label="Start"
                        className="d-flex"
                        labelClassName="me-3"
                        invalid={!startAt.isValid}
                      />
                      <div className="ms-3">
                        <Checkbox
                          onChange={(state) => setEndActive(state)}
                          checked={endAtActive}
                          label="Add optional end date"
                        />
                      </div>
                    </div>
                    <Collapse isOpen={endAtActive}>
                      <div className="d-flex align-items-center mt-3">
                        <DateAndTimeInput
                          value={endAt.value}
                          onChange={setEndAt}
                          label="End"
                          minDate={endAt.value}
                          className="d-flex"
                          labelClassName="me-4"
                          invalid={!endAt.isValid}
                        />
                      </div>
                    </Collapse>
                  </div>
                </Section>
              </div>
              <div className="pt-4 ps-4 pe-4 pb-1 mt-3 bg-light">
                <Section>
                  <div className="d-inline-block">
                    <TimeZonePicker
                      invalid={!timeZoneName.isValid}
                      value={timeZoneName.value}
                      onChange={setTimezone}
                    />
                  </div>
                </Section>
              </div>
            </div>
          </Collapse>
        </div>
      </Col>
    </Row>
  )
}

export default withStore(DeliveryStep)

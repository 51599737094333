import { action, computed, makeObservable, observable } from 'mobx'
import imagePlaceholder from '~/components/icons/image_placeholder.svg'
import { IAppDetails } from '~/dataStore/App.interface'
import {
  ActionTypes,
  CampaignType,
  ICampaignModel,
  IPreviewItem,
  IPushPayload,
  PreviewFieldType
} from '~/dataStore/Campaign/Campaign.interface'
import Shared from '~/dataStore/Campaign/Shared'
import { getCampaignTypeOptions } from '~/dataStore/CampaignTypeFacade'
import { IRegisteredField } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import StepStore from '~/dataStore/emailBuilder/StepStore'
import { PartialPreview } from '~/pages/Campaign/CampaignReview/CampaignReview.interface'
import { ALPHABET } from '~/pages/CampaignBuilder/Email/consts'
import { IPushDTO } from '../../Notification.interface'
import PushVariant from './PushVariant.model'

export default class PushNotification extends StepStore {
  appName: string | undefined

  appLogo: string | undefined

  appId: string

  isLimitedBuilder: boolean

  hideRichMedia: boolean

  private isCardCampaign: boolean

  public currentVariantId = 'A'

  public variants: PushVariant[]

  public shared = new Shared()

  //* temp solution, remove after variants are added to journeys and goals!
  public isCampaignPush: boolean

  constructor({
    app,
    campaign,
    isCardCampaign = false
  }: {
    app?: Pick<IAppDetails, 'id' | 'image' | 'name' | 'featureFlags'>
    campaign?: ICampaignModel
    isCardCampaign?: boolean
  }) {
    super()

    makeObservable(this, {
      currentVariantId: observable,
      variants: observable,
      validateStep: action.bound,
      setVariant: action.bound,
      addVariant: action.bound,
      removeVariant: action.bound,
      currentVariant: computed,
      registeredFields: computed
    })

    this.isCampaignPush = !!campaign

    this.isCardCampaign = isCardCampaign

    this.appId = app?.id
    this.appName = app?.name
    this.appLogo = app?.image
    this.isLimitedBuilder = !app?.featureFlags.pushNotificationsFullAccess
    this.hideRichMedia = !app?.featureFlags.pushRichMedia
    this.setupBlankVariant()
  }

  static limitVariant(variant: PushVariant): void {
    variant.actionButtons.setActive(false)
    variant.richMedia.setActive(false)
    variant.title.setActive(false)
    variant.actionButtons.setActive(false)
    variant.notificationAction?.destinationType.setValue(ActionTypes.OPEN_APP)
  }

  private setupBlankVariant(): void {
    const variant = new PushVariant(
      this.currentVariantId,
      this.appId,
      this.isCardCampaign,
      this.isCampaignPush
    )
    if (this.isLimitedBuilder) PushNotification.limitVariant(variant)
    if (this.hideRichMedia) variant.richMedia.setActive(false)
    this.variants = [variant]
  }

  validateStep(): void {
    this.variants.forEach((variant) => variant.validate())

    this.beenValid = true
  }

  public setVariant(variant: string): void {
    this.currentVariantId = variant
  }

  public async addVariant(type: 'duplicate' | 'blank'): Promise<void> {
    if (this.variants.length === 3) {
      return
      // throw new Error('Can not add new Variant')
    }

    const newVariant = new PushVariant(
      ALPHABET[this.variants.length].toUpperCase(),
      this.appId,
      this.isCardCampaign,
      this.isCampaignPush
    )

    if (type === 'duplicate') {
      newVariant.fillVariant(
        await this.variants[0].getDuplicatedPayload(),
        this.shared as Shared
      )
    }
    if (this.isLimitedBuilder) PushNotification.limitVariant(newVariant)
    if (this.hideRichMedia) newVariant.richMedia.setActive(false)
    this.variants.push(newVariant)
    this.setVariant(newVariant.id)
  }

  public removeVariant(variantId: string): void {
    this.variants = this.variants.filter((v) => v.id !== variantId)
    this.reassignVariantsId()
    this.reassignSelectedVariant()
  }

  private reassignVariantsId(): void {
    this.variants.forEach((v, index) => v.setId(ALPHABET[index].toUpperCase()))
  }

  private reassignSelectedVariant(): void {
    if (!this.variants.some((v) => v.id === this.currentVariantId)) {
      this.setVariant(this.variants[0].id)
    }
  }

  public get currentVariant(): PushVariant | null {
    const variant = this.variants.find((v) => v.id === this.currentVariantId)
    return variant || null
  }

  public get registeredFields(): IRegisteredField[] {
    return this.variants.flatMap((v) => v.registeredFields)
  }

  public fillStore({
    pushVariants
  }: {
    pushVariants?: (IPushPayload | IPushDTO)[]
  }): void {
    if (!pushVariants) {
      return
    }

    this.variants = pushVariants.map((variant, index) => {
      const newVariant = new PushVariant(
        ALPHABET[index].toUpperCase(),
        this.appId,
        this.isCardCampaign,
        this.isCampaignPush
      )
      newVariant.fillVariant(variant, this.shared)

      if (this.hideRichMedia) newVariant.richMedia.setActive(false)

      return newVariant
    })

    this.beenValid = true
  }

  public getPayload(): Promise<(IPushPayload | IPushDTO)[]> {
    return Promise.all(this.variants.map((variant) => variant.getPayload()))
  }

  private generatePreview(variant: PushVariant): IPreviewItem[] {
    const parts: IPreviewItem[] = []

    if (variant) {
      parts.push({
        type: PreviewFieldType.APP_HEADER,
        appLogo: this.appLogo,
        appName: this.appName
      })

      const { title, subTitle, notification, richMedia, actionButtons } =
        variant

      if (title.isActive) {
        parts.push({
          type: PreviewFieldType.HEADLINE,
          value: title.value || 'Grab your users attention'
        })
      }

      if (subTitle.isActive) {
        parts.push({
          type: PreviewFieldType.SUBHEADLINE,
          value: subTitle.value || 'Add some more important text'
        })
      }

      parts.push({
        type: PreviewFieldType.TEXT,
        value:
          notification.value ||
          'Add 1 or 2 extra lines to make your notification tempting and tap!'
      })

      if (richMedia.isActive) {
        parts.push({
          type: PreviewFieldType.ATTACHMENT,
          value: richMedia.value || imagePlaceholder,
          mediaType: richMedia.previewFileType
        })
      }

      if (actionButtons.isActive) {
        if (actionButtons.buttons.length > 0) {
          parts.push({
            type: PreviewFieldType.BUTTON,
            value: actionButtons.buttons.map((button) => ({
              label: button.value
            }))
          })
        } else {
          parts.push({
            type: PreviewFieldType.BUTTON,
            value: [{ label: 'Action Buttons' }]
          })
        }
      }
    }

    return parts
  }

  public generateCurrentVariantPreview(): PartialPreview {
    return {
      push: {
        variants: [
          {
            parts: this.generatePreview(this.currentVariant),
            goals: this.currentVariant?.goals?.getPayload()
          }
        ]
      },
      type: getCampaignTypeOptions([CampaignType.PUSH], {
        push: { variants: [{ parts: [] }] }
      })
    }
  }

  public generateAllVariantsPreview(): PartialPreview {
    const preview = {
      variants: this.variants.map((variant) => ({
        parts: this.generatePreview(variant)
      }))
    }

    return {
      push: preview,
      type: getCampaignTypeOptions([CampaignType.PUSH], {
        push: preview
      })
    }
  }
}

import { ReactElement } from 'react'
import AlertStep from './Alert/AlertStep'
import DeliveryStep from './DeliveryStep/DeliveryStep'
import ReviewStep from './ReviewStep'
import SetupStep from './SetupStep/SetupStep'
import TargetingStep from './TargetingStep/TargetingStep'

export default function CurrentStepComponent({
  name
}: {
  name?: string
}): ReactElement {
  switch (name) {
    case 'setUp':
      return <SetupStep />
    case 'alert':
      return <AlertStep />
    case 'targeting':
      return <TargetingStep />
    case 'deliver':
      return <DeliveryStep />
    case 'review':
      return <ReviewStep />
    default:
      return <SetupStep />
  }
}
